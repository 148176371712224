import {AfterViewInit, Component} from '@angular/core';
import {View} from '../../../common/view';
import {EnvironmentVariable, HasEnvironmentVariables} from '@flux-capacitor-io/flux-host-typescriptmodels';
import {map} from 'rxjs/operators';
import {Observable} from 'rxjs';
import {ComparatorChain} from '../../../common/comparator-chain';
import {
  EnvironmentVariableDetailsComponent
} from '../environment-variable-details/environment-variable-details.component';
import {filterByTerm, sort} from '../../../common/utils';

@Component({
  selector: 'app-environment-variable-overview',
  templateUrl: './environment-variable-overview.component.html',
  styleUrls: ['./environment-variable-overview.component.scss']
})
export class EnvironmentVariableOverviewComponent extends View implements AfterViewInit {
  comparator: ComparatorChain = new ComparatorChain("details.name");

  term: string;

  query: Observable<EnvironmentVariable[]>;
  data: EnvironmentVariable[] = [];

  ngAfterViewInit(): void {
    this.executeQuery();
  }

  executeQuery(debounce: number = 500) {
    this.query = this.subscribeTo("getEntity")
      .pipe(map((e : HasEnvironmentVariables) => e.environmentVariables))
      .pipe(map((t: EnvironmentVariable[]) => t.filter(filterByTerm(this.term))))
      .pipe(map((values : EnvironmentVariable[]) => sort(values, this.comparator)));
  }

  create = () => this.openModal(EnvironmentVariableDetailsComponent, <EnvironmentVariable> { details: {}});

  trackByForRecord(index: number, record: EnvironmentVariable) {
    return record.details.name;
  }

}
