<app-overview [query]="query" (dataChange)="data = $event" [windowScroll]="true">
  <div class="header-bar align-items-center row mb-4">
    <div class="col">
      <app-filter [(filterTerm)]="term" (onUpdate)="executeQuery()"
                  localStorageKey="history-overview-term" placeholder="Search"></app-filter>
    </div>
    <div class="col-auto ms-auto">
      <div class="form-check form-switch">
        <input class="form-check-input" type="checkbox" role="switch" id="taskHistory"
               [checked]="includeTaskItems.getValue()" (click)="toggleShowTaskHistory()">
        <label class="form-check-label" for="taskHistory">Show task history</label>
      </div>
    </div>
  </div>
  <div class="overview-items">
    <div class="row mx-0">
      <ng-container *ngFor="let historyItem of data; trackBy: trackByForRecord">
        <div class="col-12">
          <app-history-overview-item class="w-100 h-100" [historyItem]="historyItem"></app-history-overview-item>
        </div>
      </ng-container>
    </div>
  </div>
  <div class="empty-container col-12 h-100 d-flex align-items-center justify-content-center flex-column">
    <span class="h1 mb-2">No history items found</span>
    <span class="text-secondary">Try adjusting your filters or search term</span>
  </div>
</app-overview>
