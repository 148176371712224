import {AfterViewInit, Component} from '@angular/core';
import {Handler} from "../../../common/handler";
import {View} from "../../../common/view";
import {Organisation} from "@flux-capacitor-io/flux-host-typescriptmodels";
import {ComparatorChain} from "../../../common/comparator-chain";
import {Observable} from "rxjs";
import {map} from "rxjs/operators";
import {filterByTerm, sort} from "../../../common/utils";
import {
  OrganisationDetailsComponentData,
  OrganisationDetailsModalComponent
} from "../organisation-details-modal/organisation-details-modal.component";

@Component({
  selector: 'app-organisation-overview',
  templateUrl: './organisation-overview.component.html',
  styleUrls: ['./organisation-overview.component.scss']
})
@Handler()
export class OrganisationOverviewComponent extends View implements AfterViewInit {
  comparator: ComparatorChain = new ComparatorChain("details.name", "organisationId");

  term: string;
  query: Observable<Organisation[]>;
  data: Organisation[] = [];

  ngAfterViewInit(): void {
    this.executeQuery();
  }

  create = () => this.openModal(OrganisationDetailsModalComponent, <OrganisationDetailsComponentData>{
    organisation: null
  });

  executeQuery = () => {
    this.query = this.subscribeTo("getOrganisations")
      .pipe(map((o: Organisation[]) => o.filter(filterByTerm(this.term))))
      .pipe(map(c => sort(c, this.comparator)));
  };

  trackByForRecord(index: number, record: Organisation) {
    return record.organisationId;
  }
}
