import {Component, ElementRef, OnChanges, OnInit, SimpleChanges, ViewChild,} from '@angular/core';
import {Observable, of, switchMap} from "rxjs";
import {catchError} from "rxjs/operators";
import {Handler} from "../../../common/handler";
import {View} from "../../../common/view";
import {GetIssue, GetIssueEntries, Issue, IssueEntry} from "@flux-capacitor-io/flux-host-typescriptmodels";
import {subscribeTo} from "../../../common/app-common-utils";

@Component({
  selector: 'app-issue-navigator',
  templateUrl: './issue-navigator.component.html',
  styleUrls: ['./issue-navigator.component.scss']
})
@Handler()
export class IssueNavigatorComponent extends View implements OnInit {
  data: IssueNavigatorComponentData;
  issue: Issue;
  issueId: string;

  entries: IssueEntry[];
  index: number = 0;

  ngOnInit(): void {
    this.issueId = this.data?.issueId;
    this.fetchIssueEntries().subscribe(() => this.fetchError());
  }

  fetchIssueEntries(): Observable<any> {
    if (this.issueId) {
      return subscribeTo('host.flux.service.monitoring.issue.api.GetIssueEntries', <GetIssueEntries> {
        issueId: this.issueId,
        size: 100
      }, { caching: false }).pipe(
        switchMap((newEntries: IssueEntry[]) => {
          if (this.entries) {
            const existingIds = new Set(this.entries.map(entry => entry.sequence));
            newEntries.forEach(entry => {
              if (!existingIds.has(entry.sequence)) {
                this.entries.push(entry);
              }
            });
          } else {
            this.entries = newEntries;
          }
          this.entries.sort((a, b) => (a.sequence > b.sequence ? -1 : 1));
          return of([]);
        }),
        catchError(() => of([]))
      );
    } else {
      return of([]);
    }
  }

  nextEntry() {
    if (this.entries?.length) {
      this.index = (this.index - 1 + this.entries.length) % this.entries.length;
    }
  }

  prevEntry() {
    if (this.entries?.length) {
      this.index = (this.index + 1) % this.entries.length;
    }
  }

  hasNextEntry(): boolean {
    return this.index > 0;
  }

  hasPrevEntry(): boolean {
    return this.index < this.entries.length - 1;
  }

  goToBeginning(): void {
    this.fetchIssueEntries().subscribe(() => this.index = this.entries.length - 1);
  }

  goToEnd(): void {
    this.fetchIssueEntries().subscribe(() => this.index = 0);
  }

  fetchError() {
    if (this.issueId) {
      this.subscribeTo("host.flux.service.monitoring.issue.api.GetIssue", <GetIssue>{
        issueId: this.issueId
      }).subscribe((issue: Issue) => this.issue = issue);
    }
  }
}

export interface IssueNavigatorComponentData {
  issueId: string;
}
