import {AfterViewInit, Component, ElementRef, Type, ViewChild, ViewContainerRef} from '@angular/core';
import {closeConfirmationModal} from '../../app-utils';
import {Handler} from "src/app/common/handler";
import {HandleEvent} from "../handle-event";
import {HandleCommand} from "../handle-command";

@Component({
  selector: 'app-confirmation-modal',
  templateUrl: './confirmation-modal.component.html',
  styleUrls: ['./confirmation-modal.component.css']
})
@Handler()
export class ConfirmationModalComponent implements AfterViewInit {

  @ViewChild('modal', {static: true}) modal: ElementRef;
  @ViewChild('container', {read: ViewContainerRef}) container: ViewContainerRef;

  ngAfterViewInit(): void {
    $(this.modal.nativeElement).on('hidden.bs.modal', () => this.container.clear());
  }

  @HandleCommand("openConfirmationModal")
  openConfirmationModal(event: ConfirmationModalPayload) {
    this.container.clear();
    const componentRef = this.container.createComponent(<any>event.component);
    componentRef.instance['data'] = event.data;
    componentRef.instance['callback'] = close => {
      closeConfirmationModal();
      if (event.callback) {
        event.callback(close);
      }
    }
    $(componentRef.location.nativeElement).addClass('modal-content');
    $(this.modal.nativeElement).modal('show');
  }

  @HandleCommand("closeConfirmationModal")
  closeConfirmationModal() {
    $(this.modal.nativeElement).modal('hide');
  }
}

export interface ConfirmationModalPayload {
  component : Type<any>;
  data? : any;
  callback : (boolean) => any;
}
