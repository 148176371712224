import {Component, OnInit} from '@angular/core';
import {Cluster, ClusterSize, ConfigureCluster, Team} from "@flux-capacitor-io/flux-host-typescriptmodels";
import {uuid} from "../../../common/utils";
import {Handler} from "../../../common/handler";
import {HandleQuery} from "../../../common/handle-query";
import {Observable, of} from "rxjs";
import {View} from "../../../common/view";

@Component({
  selector: 'app-cluster-details-modal',
  templateUrl: './cluster-details-modal.component.html',
  styleUrls: ['./cluster-details-modal.component.scss']
})
@Handler()
export class ClusterDetailsModalComponent extends View implements OnInit {
  data: ClusterDetailsModalComponentData;
  isNewRecord: boolean;
  clusterSizes: Observable<ClusterSize[]>;

  ngOnInit() {
    this.isNewRecord = !this.data.cluster?.clusterId;
    this.data.cluster = this.data.cluster || this.defaultRecord();
    this.clusterSizes = this.sendQuery("getClusterSizes");
  }

  updateInfo = () => this.sendCommand("host.flux.service.organisation.api.ConfigureCluster", <ConfigureCluster>{
    teamId: this.data.team.teamId,
    clusterId: this.data.cluster.clusterId,
    details: this.data.cluster.details
  });

  defaultRecord = (): Cluster => ({
    clusterId: uuid(),
    details: {
      descriptor: {

      }
    }
  });

  @HandleQuery("getCluster")
  getCluster() {
    return of(this.data.cluster);
  }
}

export interface ClusterDetailsModalComponentData {
  cluster: Cluster;
  team: Team;
}
