import {Component, Input, OnInit} from '@angular/core';
import {View} from "../../../../common/view";
import {Cluster, Organisation} from "@flux-capacitor-io/flux-host-typescriptmodels";
import {ClusterStatusInfo} from "../../../clusters/cluster-overview/cluster-overview.component";
import {cloneDeep} from "lodash";
import {Handler} from "../../../../common/handler";
import {HandleQuery} from "../../../../common/handle-query";
import {map} from "rxjs/operators";
import {Observable, of} from "rxjs";
import clusterStatusInfos from "../../../clusters/cluster-status-info.json";
import {
  ClusterDetailsModalComponent,
  ClusterDetailsModalComponentData
} from "../../../clusters/cluster-details-modal/cluster-details-modal.component";

@Component({
  selector: 'app-organisation-overview-item',
  templateUrl: './organisation-overview-item.component.html',
  styleUrls: ['./organisation-overview-item.component.scss']
})
@Handler()
export class OrganisationOverviewItemComponent extends View implements OnInit {
  organisation: Organisation;
  clusters: Cluster[];

  ngOnInit(): void {
  }

  @Input("organisation")
  set setOrganisation(o: Organisation) {
    this.organisation = o;
    this.clusters = this.organisation.teams.flatMap(t => t.clusters);
  }

  trackByClusterId = (index: number, record: Cluster) => record.clusterId;

  configureCluster = (cluster?: Cluster) => this.openModal(ClusterDetailsModalComponent, <ClusterDetailsModalComponentData>{
    cluster: cloneDeep(cluster),
    team: this.organisation.teams[0]
  });

  @HandleQuery("getOrganisation")
  getOrganisation(): Observable<Organisation> {
    return this.subscribeTo("getOrganisations").pipe(map(
      (organisations: Organisation[]) => organisations.find(o => o.organisationId === this.organisation.organisationId)));
  }
}
