import {APP_INITIALIZER, Injector, LOCALE_ID, NgModule} from '@angular/core';
import {BrowserModule} from '@angular/platform-browser';

import {AppRoutingModule} from './app-routing.module';
import {AppComponent} from './app.component';
import {HttpClientModule} from "@angular/common/http";
import {CommonModule, DecimalPipe, TitleCasePipe} from "@angular/common";
import {FormsModule} from "@angular/forms";
import {CommonsModule} from "./common/commons.module";
import {CommandGateway} from "./common/command-gateway";
import {QueryGateway} from "./common/query-gateway";
import {EventGateway} from "./common/event-gateway";
import {TimestampPipe} from "./common/timestamp.pipe";
import {HomeComponent} from "./views/home/home.component";
import {TopMenuBarComponent} from './views/top-menu-bar/top-menu-bar.component';
import {ClusterOverviewComponent} from './views/clusters/cluster-overview/cluster-overview.component';
import {HandlerRegistry} from "./common/handler-registry.service";
import {
  ClusterOverviewItemComponent
} from './views/clusters/cluster-overview/cluster-overview-item/cluster-overview-item.component';
import {InjectorProvider} from './common/app-common-utils';
import {ClusterDetailsModalComponent} from './views/clusters/cluster-details-modal/cluster-details-modal.component';
import {
  ApplicationDetailsModalComponent
} from './views/applications/application-details-modal/application-details-modal.component';
import {BreadcrumbComponent} from './views/top-menu-bar/bread-crump/breadcrumb.component';
import {RouterHandler} from "./routing/router-handler";
import {ClusterDetailsComponent} from './views/clusters/cluster-details/cluster-details.component';
import {ApplicationDetailsComponent} from './views/applications/application-details/application-details.component';
import {ApplicationRoutingComponent} from './views/applications/application-routing/application-routing.component';
import {ClusterRoutingComponent} from "./views/clusters/cluster-routing/cluster-routing.component";
import {ApplicationOverviewComponent} from './views/applications/application-overview/application-overview.component';
import {ClusterStatusComponent} from './views/clusters/cluster-status/cluster-status.component';
import {IssuesOverviewComponent} from './views/issues/issues-overview/issues-overview.component';
import {
  IssueOverviewItemComponent
} from './views/issues/issues-overview/issue-overview-item/issue-overview-item.component';
import {IssueNavigatorComponent} from "./views/issues/issue-navigator/issue-navigator.component";
import {IssueEntryComponent} from "./views/issues/issue-entry/issue-entry.component";
import {IssueActionsDropdownComponent} from "./views/issues/issue-actions-dropdown/issue-actions-dropdown.component";
import {
  SlackAlertChannelDetailsComponent
} from "./views/issues/slack-alert-channel-details/slack-alert-channel-details.component";
import {TaskSummaryComponent} from './views/tasks/task-summary/task-summary.component';
import {TaskService} from "./views/tasks/task-service";
import {
  ApplicationOverviewItemComponent
} from './views/applications/application-overview/application-overview-item/application-overview-item.component';
import {HistoryOverviewComponent} from './views/history/history-overview/history-overview.component';
import {
  HistoryOverviewItemComponent
} from './views/history/history-overview/history-overview-item/history-overview-item.component';
import {TaskOverviewComponent} from './views/tasks/task-overview/task-overview.component';
import {TaskOverviewItemComponent} from './views/tasks/task-overview/task-overview-item/task-overview-item.component';
import {TaskStatusComponent} from './views/tasks/task-status/task-status.component';
import {EditProfileGeneralComponent} from "./views/edit-profile/edit-profile-general/edit-profile-general.component";
import {EditProfileComponent} from "./views/edit-profile/edit-profile.component";
import {UserOverviewComponent} from './views/users/user-overview/user-overview.component';
import {UserOverviewItemComponent} from './views/users/user-overview/user-overview-item/user-overview-item.component';
import {AuthorisationDetailsComponent} from "./views/users/authorisation-details/authorisation-details.component";
import {UserDetailsComponent} from './views/users/user-details/user-details.component';
import {OrganisationDetailsComponent} from './views/organisations/organisation-details/organisation-details.component';
import {OrganisationRoutingComponent} from './views/organisations/organisation-routing/organisation-routing.component';
import {
  OrganisationDetailsModalComponent
} from './views/organisations/organisation-details-modal/organisation-details-modal.component';
import {
  OrganisationOverviewComponent
} from './views/organisations/organisation-overview/organisation-overview.component';
import {
  OrganisationOverviewItemComponent
} from './views/organisations/organisation-overview/organisation-overview-item/organisation-overview-item.component';
import {ApiKeyOverviewComponent} from './views/api-keys/api-key-overview/api-key-overview.component';
import {
  ApiKeyOverviewItemComponent
} from './views/api-keys/api-key-overview/api-key-overview-item/api-key-overview-item.component';
import {UsageOverviewComponent} from './views/usage/usage-overview/usage-overview.component';
import {
  UsageOverviewItemComponent
} from './views/usage/usage-overview/usage-overview-item/usage-overview-item.component';
import {
  ClusterOverviewItemApplicationComponent
} from './views/clusters/cluster-overview/cluster-overview-item/cluster-overview-item-application/cluster-overview-item-application.component';
import {
  OrganisationOverviewItemClusterComponent
} from './views/organisations/organisation-overview/organisation-overview-item/organisation-overview-item-cluster/organisation-overview-item-cluster.component';
import {
  EnvironmentVariableOverviewComponent
} from './views/environment-variables/environment-variable-overview/environment-variable-overview.component';
import {
  EnvironmentVariableDetailsComponent
} from './views/environment-variables/environment-variable-details/environment-variable-details.component';
import {
  EnvironmentVariableOverviewItemComponent
} from './views/environment-variables/environment-variable-overview/environment-variable-overview-item/environment-variable-overview-item.component';
import {AuthConfig, OAuthModule, OAuthStorage} from "angular-oauth2-oidc";
import {environment} from '../environments/environment';
import {StorageService} from "./authentication/storage.service";
import {StatehandlerService, StatehandlerServiceImpl} from "./authentication/statehandler.service";
import {
  StatehandlerProcessorService,
  StatehandlerProcessorServiceImpl
} from "./authentication/statehandler-processor.service";
import {CookieModule} from "ngx-cookie";
import {EncryptComponent} from './views/admin/encrypt/encrypt.component';
import {InvoiceOverviewComponent} from './views/invoice/invoice-overview/invoice-overview.component';
import {
  InvoiceOverviewItemComponent
} from './views/invoice/invoice-overview/invoice-overview-item/invoice-overview-item.component';
import {EditProfileSecurityComponent} from "./views/edit-profile/edit-profile-security/edit-profile-security.component";
import { PasskeyOverviewComponent } from './views/edit-profile/edit-profile-security/passkey-overview/passkey-overview.component';
import { PasskeyOverviewItemComponent } from './views/edit-profile/edit-profile-security/passkey-overview/passkey-overview-item/passkey-overview-item.component';
import { PasskeyStatusComponent } from './views/edit-profile/edit-profile-security/passkey-overview/passkey-status/passkey-status.component';

const authConfig: AuthConfig = {
  scope: 'openid profile email offline_access',
  responseType: 'code',
  oidc: true,
  clientId: environment.auth.clientId,
  issuer: environment.auth.domain,
  redirectUri: 'http://localhost:4200/auth/callback',
  postLogoutRedirectUri: 'http://localhost:4200',
  requireHttps: false
};

@NgModule({
  declarations: [
    AppComponent,

    // Signed in
    HomeComponent,
    TopMenuBarComponent,
    BreadcrumbComponent,

    // User profile
    EditProfileComponent,
    EditProfileGeneralComponent,
    EditProfileSecurityComponent,
    PasskeyOverviewComponent,
    PasskeyOverviewItemComponent,
    PasskeyStatusComponent,

    // History
    HistoryOverviewComponent,
    HistoryOverviewItemComponent,

    // Organisations
    OrganisationOverviewComponent,
    OrganisationOverviewItemComponent,
    OrganisationOverviewItemClusterComponent,
    OrganisationRoutingComponent,
    OrganisationDetailsComponent,
    OrganisationDetailsModalComponent,

    // Clusters
    ClusterOverviewComponent,
    ClusterOverviewItemComponent,
    ClusterOverviewItemApplicationComponent,
    ClusterRoutingComponent,
    ClusterDetailsComponent,
    ClusterDetailsModalComponent,
    ClusterStatusComponent,

    // Applications
    ApplicationRoutingComponent,
    ApplicationDetailsComponent,
    ApplicationDetailsModalComponent,
    ApplicationOverviewComponent,
    ApplicationOverviewItemComponent,

    // Issues
    IssuesOverviewComponent,
    IssueOverviewItemComponent,
    IssueNavigatorComponent,
    IssueEntryComponent,
    IssueActionsDropdownComponent,
    SlackAlertChannelDetailsComponent,

    // Tasks
    TaskSummaryComponent,
    TaskOverviewComponent,
    TaskOverviewItemComponent,
    TaskStatusComponent,

    // Usage
    UsageOverviewComponent,
    UsageOverviewItemComponent,

    // Users
    UserOverviewComponent,
    UserOverviewItemComponent,
    UserDetailsComponent,
    AuthorisationDetailsComponent,

    // API keys
    ApiKeyOverviewComponent,
    ApiKeyOverviewItemComponent,
    EnvironmentVariableOverviewComponent,
    EnvironmentVariableDetailsComponent,
    EnvironmentVariableOverviewItemComponent,

    //Admin
    EncryptComponent,

    // Invoicing
    InvoiceOverviewComponent,
    InvoiceOverviewItemComponent,
  ],
  imports: [
    BrowserModule,
    HttpClientModule,
    AppRoutingModule,
    CommonModule,
    FormsModule,
    CommonsModule,
    CookieModule.withOptions(),
    OAuthModule.forRoot({
      resourceServer: {
        allowedUrls: [`${environment.auth.domain}/admin/v1`, `${environment.auth.domain}/management/v1`, `${environment.auth.domain}/auth/v1/`],
        sendAccessToken: true,
      },
    }),
  ],
  providers: [
    HandlerRegistry,
    CommandGateway,
    QueryGateway,
    EventGateway,
    TitleCasePipe,
    TimestampPipe,
    DecimalPipe,
    RouterHandler,
    TaskService,
    {provide: LOCALE_ID, useValue: 'nl-NL'},
    {
      provide: APP_INITIALIZER,
      useFactory: (stateHandler: StatehandlerService) => () => stateHandler.initStateHandler(),
      multi: true,
      deps: [StatehandlerService],
    },
    {
      provide: AuthConfig,
      useValue: authConfig,
    },
    {
      provide: StatehandlerProcessorService,
      useClass: StatehandlerProcessorServiceImpl,
    },
    {
      provide: StatehandlerService,
      useClass: StatehandlerServiceImpl,
    },
    {
      provide: OAuthStorage,
      useClass: StorageService,
    }
  ],
  bootstrap: [AppComponent]
})
export class AppModule {
  constructor(injector: Injector) {
    InjectorProvider.injector = injector;
    InjectorProvider.injector.get(RouterHandler);
    InjectorProvider.injector.get(TaskService);
  }
}
