import {AfterViewInit, Component, TemplateRef, ViewChild} from '@angular/core';
import {View} from "src/app/common/view";
import {Handler} from "src/app/common/handler";
import {
  FacetFilter,
  FacetResult,
  GetFacets,
  GetIssueEntries,
  GetIssues,
  Issue,
  SlackAlertChannel
} from "@flux-capacitor-io/flux-host-typescriptmodels";
import {Observable} from "rxjs";
import {sendFacetsQuery, subscribeTo} from "../../../common/app-common-utils";
import {map} from "rxjs/operators";
import {filterByTerm} from "../../../common/utils";
import {FacetUtils} from "../../../common/facets/facet-utils";
import {HandleQuery} from "../../../common/handle-query";
import {HandleCommand} from "../../../common/handle-command";
import {
  SlackAlertChannelDetailsComponent,
  SlackAlertChannelDetailsComponentData
} from "../slack-alert-channel-details/slack-alert-channel-details.component";

@Component({
  selector: 'app-issues-overview',
  templateUrl: './issues-overview.component.html',
  styleUrls: ['./issues-overview.component.scss']
})
@Handler()
export class IssuesOverviewComponent extends View implements AfterViewInit {
  facetUtils = FacetUtils;
  term: string;
  query: Observable<Issue[]>;
  facets: Observable<FacetResult>;
  facetFilters: FacetFilter[];
  data: Issue[] = [];
  slackChannels: SlackAlertChannel[] = [];

  @ViewChild("filtersInModal") filtersTemplate: TemplateRef<any>;

  ngAfterViewInit(): void {
    this.executeQuery();
    this.updateAlertChannels();
  }

  executeQuery() {
    const query: GetIssues = {facetFilters: this.facetFilters};
    this.query = this.subscribeTo("getIssues", query)
      .pipe(map((o: Issue[]) => o.filter(filterByTerm(this.term))));
    this.facets = sendFacetsQuery("host.flux.service.monitoring.issue.api.GetIssues",
      <GetFacets>{query: query}, {caching: false});
  }

  updateAlertChannels() {
    this.subscribeTo("findAlertChannels").subscribe(channels => this.slackChannels = channels);
  }

  trackByForRecord(index: number, record: Issue) {
    return record.issueId;
  }

  openFiltersInModal = () => this.openModal(this.filtersTemplate, null);

  openSlackModal(record: SlackAlertChannel): void {
    this.sendQuery("getCluster").subscribe(c => {
      this.openModal(SlackAlertChannelDetailsComponent, <SlackAlertChannelDetailsComponentData>{
        alertChannelId: record.alertChannelId,
        clusterId: c.clusterId,
        details: record.details
      });
    });
  }

  addNewSlackChannel(): void {
    const newChannel: SlackAlertChannel = {
      details: {
        name: '',
        url: '',
      },
      type: "slack"
    };
    this.openSlackModal(newChannel);
  }

  trackByChannel = (index: number, channel: SlackAlertChannel) => channel.alertChannelId;

  @HandleCommand("reloadIssues", {global: true})
  reloadIssues() {
    this.executeQuery();
  }

  @HandleQuery("getIssueEntries")
  getIssueEntries(query: GetIssueEntries) {
    return subscribeTo('host.flux.service.monitoring.issue.api.GetIssueEntries', query, {caching: false});
  }
}
