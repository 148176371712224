<div class="card hover-show w-100" [ngClass]="isMuted(issue) || isResolved(issue) ? 'text-muted' : ''">
  <div class="card-body">
    <div class="row d-flex gx-3">
      <div class="col-auto notranslate d-flex align-items-center justify-content-between flex-shrink-0">
        <span *ngIf="getErrorStatusConfig(issue) as config" class="h2 m-0" [ngClass]="'text-'+config.colorClass">
          <i class="bi" [ngClass]="config.icon"></i>
        </span>
      </div>
      <div class="col flex-grow-1 w-0">
        <div class="mw-100 mb-1">
          <div class="text-truncate">
            <a (click)="handleIssueClick($event, issue.issueId)">
              <span class="h3 notranslate hover-underline pointer">
                {{ issue.details.errorMessage || issue.details.message || 'Custom error' }}
              </span>
            </a>
          </div>
        </div>
        <div class="row gx-3 mt-2">
          <div class="text-muted col-6 col-md-1">
            <span class="bi bi-hash pe-2"></span>
            <span>{{ issue.count }}</span>
          </div>
          <div class="text-muted notranslate col-6 col-md-4">
            <span class="bi bi-disc pe-3"></span>
            <span>{{ issue.details.application }}</span>
          </div>
          <div class="text-muted notranslate col-6 col-md-2">
            <span class="bi bi-geo-alt pe-3"></span>
            <span>{{ issue.details.environment }}</span>
          </div>
          <div class="text-muted col-6 col-md-3 notranslate">
            <span class="bi bi-clock pe-3"></span>
            <span>{{ issue.lastSeen | timestamp }}</span>
          </div>
          <div *ngIf="isMuted(issue) && issue.muteDeadline" class="text-muted notranslate col-6 col-md-2">
            <span class="bi bi-volume-mute pe-3"></span>
            <span>{{ issue.muteDeadline | timestamp }}</span>
          </div>
        </div>
      </div>
      <div class="col-auto ms-auto mt-n3 me-n3 dropup">
        <button type="button" class="btn text-body" role="button" data-bs-toggle="dropdown"
                data-bs-auto-close="outside" aria-expanded="false">
          <i class="bi bi-three-dots-vertical"></i>
        </button>
        <app-issue-actions-dropdown [issue]="issue"></app-issue-actions-dropdown>
      </div>
    </div>
  </div>
</div>
