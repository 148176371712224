<div class="row">
  <div class="col-auto">
    <i class="bi bi-window me-2"></i>
  </div>
  <div class="col text-truncate notranslate d-inline-flex align-items-start">
    <a class="text-black hover-underline" [routerLink]="getApplicationLink()"><small>{{application.details.name}}</small></a>
  </div>
  <div class="col-4 notranslate">
    <app-task-summary></app-task-summary>
  </div>
</div>
