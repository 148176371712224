import {Component, Input} from '@angular/core';
import {ClusterStatusInfo} from "../cluster-overview/cluster-overview.component";

@Component({
  selector: 'app-cluster-status',
  templateUrl: './cluster-status.component.html',
  styleUrls: ['./cluster-status.component.scss']
})
export class ClusterStatusComponent {
  @Input() clusterStatusInfo: ClusterStatusInfo;
}
