<app-form (submit)="updateInfo()">
  <div class="card">
    <div class="card-header">
      <h3 class="card-header-title">
        <i class="bi bi-window-stack me-2"></i>
        <span>Organisation details</span>
      </h3>
    </div>

    <div class="card-body">
      <div class="row">
        <div class="col-12 col-md-6">
          <app-form-field label="Name" [editMode]="true" [value]="data.organisation.details.name">
            <input [(ngModel)]="data.organisation.details.name" type="text" class="form-control" required>
          </app-form-field>
        </div>
      </div>
    </div>

    <div class="card-footer">
      <div class="d-flex justify-content-between">
        <a class="btn btn-secondary" data-bs-dismiss="modal">Cancel</a>
        <button type="submit" class="btn btn-primary">Save changes</button>
      </div>
    </div>
  </div>
</app-form>
