import {Component, Input} from '@angular/core';
import moment from "moment";
import {
  Issue,
  IssueStatus,
  MuteIssue,
  ReopenIssue,
  ResolveIssue,
  UnmuteIssue
} from "@flux-capacitor-io/flux-host-typescriptmodels";
import {Handler} from "../../../common/handler";
import {View} from "../../../common/view";

@Component({
  selector: 'app-issue-actions-dropdown',
  templateUrl: './issue-actions-dropdown.component.html',
  styleUrls: ['./issue-actions-dropdown.component.scss']
})
@Handler()
export class IssueActionsDropdownComponent extends View {
  @Input() issue: Issue;
  muteDate: string;

  resolveIssue = (issue: Issue) => {
    this.sendCommand("host.flux.service.monitoring.issue.api.ResolveIssue", <ResolveIssue>{
      issueId: issue.issueId
    }, () => {
      issue.status = IssueStatus.RESOLVED;
      this.sendCommandAndForget("reloadIssues");
    });
  }

  unmuteIssue = (issue: Issue) => {
    this.sendCommand("host.flux.service.monitoring.issue.api.UnmuteIssue", <UnmuteIssue>{
      issueId: issue.issueId
    }, () => {
      issue.status = IssueStatus.OPEN;
      issue.muteDeadline = null;
      this.sendCommandAndForget("reloadIssues");
    });
  }

  muteIssue(issue: Issue) {
    this.sendCommand("host.flux.service.monitoring.issue.api.MuteIssue", <MuteIssue>{
      issueId: issue.issueId,
      deadline: this.muteDate
    }, () => {
      issue.status = IssueStatus.MUTED;
      issue.muteDeadline = this.muteDate;
      this.muteDate = null;
      this.sendCommandAndForget("reloadIssues");
    });
  }

  reopenIssue = (issue: Issue) => {
    this.sendCommand("host.flux.service.monitoring.issue.api.ReopenIssue", <ReopenIssue>{
      issueId: issue.issueId
    }, () => {
      issue.status = IssueStatus.OPEN;
      this.sendCommandAndForget("reloadIssues");
    });
  }

  setMuteDeadline(duration: string) {
    switch (duration) {
      case '1d':
        this.muteDate = moment().add(1, 'day').toISOString();
        break;
      case '1w':
        this.muteDate = moment().add(1, 'week').toISOString();
        break;
      case '1m':
        this.muteDate = moment().add(1, 'month').toISOString();
        break;
      case 'indefinite':
        this.muteDate = null;
        break;
    }
    this.muteIssue(this.issue);
  }
}
