<div class="card hover-show w-100">
  <div class="card-body mb-3">
    <app-confirmation-toggle *ngIf="appContext.isAdmin()" (confirmed)="revokeUser()">
      <button type="button" class="btn position-absolute top-0 start-0 to-show"
              role="button" aria-expanded="false">
        <i class="bi bi-trash"></i>
      </button>
      <div class="modal-content">
        <div class="modal-header">
          <h3 class="modal-title">Revoke user access</h3>
          <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>

        </div>
        <div class="modal-body">
          <p>Are you sure you want to revoke all user access to your organisation?</p>
        </div>
        <div class="modal-footer justify-content-between">
          <a type="button" class="btn btn-secondary" data-bs-dismiss="modal">Cancel</a>
          <button type="button" class="btn btn-primary" (click)="revokeUser()" data-bs-dismiss="modal">
            Revoke access
          </button>
        </div>
      </div>
    </app-confirmation-toggle>
    <div *ngIf="appContext.isAdmin()" class="position-absolute top-0 end-0">
      <button type="button" class="btn to-show-inline" role="button" data-bs-title="Impersonate user"
              aria-expanded="false" (click)="impersonate()">
        <i class="bi bi-person-badge"></i>
      </button>
    </div>
    <h2 class="card-title text-center notranslate">
      <i class="bi me-2 bi-person"></i>
      <span class="me-2">{{user.details.firstName}} {{user.details.lastName}}</span>
    </h2>
    <h5 class="text-muted text-center notranslate">
      <span class="vertical-align-middle">{{user.details.email}}</span>
    </h5>
    <hr class="card-divider m-0 mb-3">
    <div class="list-group-flush mb-n3 list-group">
      <div *ngFor="let authorisation of authorisations() | async; trackBy: trackByForAuthorisation"
           class="list-group-item py-3 pointer hover-underline" (click)="editAuthorisation(authorisation)">
        <div class="row gx-0">
          <div class="col-auto">
            <i class="bi me-2 bi-diagram-3"></i>
          </div>
          <div class="text-truncate notranslate" [ngClass]="authorisation.entities?.length ? 'col-11 col-md-4' : 'col'"><small>{{authorisation.organisationName}}</small></div>
          <div *ngIf="authorisation.entities?.length" class="col-10 col-md text-truncate notranslate">
            <span class="me-2" *ngFor="let entity of authorisation.entities; trackBy: trackByForEntity">
              <i class="bi me-2" [ngClass]="getEntityTypeIcon(entity.type)"></i>
              <small>{{entity.name}}</small>
            </span>
          </div>
          <div class="col-2 text-end text-truncate"><small>{{authorisation.authorisation.role}}</small></div>
        </div>
      </div>
      <div class="list-group-item py-3 pointer hover-underline" (click)="openAuthoriseUserDetails()">
        <div class="row">
          <div class="col-auto">
            <i class="bi me-2 bi-plus-circle"></i>
          </div>
          <div class="col text-truncate"><small>Add new authorisation</small></div>
        </div>
      </div>
    </div>
  </div>
</div>
