import {Component, Input} from '@angular/core';
import {Handler} from "../../../../../common/handler";
import {View} from "../../../../../common/view";
import {Application, Cluster} from "@flux-capacitor-io/flux-host-typescriptmodels";
import {HandleQuery} from "../../../../../common/handle-query";
import {Observable} from "rxjs";
import {TaskWithContext} from "../../../cluster-routing/cluster-routing.component";
import {map} from "rxjs/operators";

@Component({
  selector: 'app-cluster-overview-item-application',
  templateUrl: './cluster-overview-item-application.component.html',
  styleUrls: ['./cluster-overview-item-application.component.scss']
})
@Handler()
export class ClusterOverviewItemApplicationComponent extends View {
  @Input() cluster: Cluster;
  @Input() application: Application;

  getApplicationLink = () => `../cluster/${this.cluster.clusterId}/application/${this.application.applicationId}`;

  @HandleQuery("getApplication")
  getApplication(): Observable<Application> {
    return this.subscribeTo("getCluster").pipe(map((c: Cluster) => c.applications
      .find(a => a.applicationId === this.application.applicationId)));
  }

  @HandleQuery("getTasks")
  getTasks(): Observable<TaskWithContext[]> {
    return this.subscribeTo("getApplication").pipe(map((a: Application) => a.tasks.map(t => (<TaskWithContext>{
      task: t,
      application: a
    }))));
  }
}
