import {Component, Input, OnInit} from '@angular/core';
import {View} from "../../../../common/view";
import {TaskStatus} from "@flux-capacitor-io/flux-host-typescriptmodels";
import {Observable, of} from "rxjs";
import {sendQuery} from "../../../../common/app-common-utils";
import {map, tap} from "rxjs/operators";
import {HandleQuery} from "../../../../common/handle-query";
import {Handler} from "../../../../common/handler";
import {TaskStatusInfo} from "../../task-status/task-status.component";
import {TaskWithContext} from "../../../clusters/cluster-routing/cluster-routing.component";

@Component({
  selector: 'app-task-overview-item',
  templateUrl: './task-overview-item.component.html',
  styleUrls: ['./task-overview-item.component.scss']
})
@Handler()
export class TaskOverviewItemComponent extends View implements OnInit {
  @Input() taskWithContext: TaskWithContext;
  cachedColors: Map<TaskStatus, string> = new Map();
  taskStatusInfo: TaskStatusInfo;

  ngOnInit() {
    this.subscribeTo("getTaskStatusInfo", this.taskWithContext.task).subscribe(taskStatusInfo => this.taskStatusInfo = taskStatusInfo);
  }

  getColor = (status: TaskStatus): Observable<string> => this.cachedColors.has(status)
    ? of(this.cachedColors.get(status))
    : sendQuery("getTaskStatusColor", status).pipe(tap(c => this.cachedColors.set(status, c)));

  @HandleQuery("getTask")
  getTask(): Observable<TaskWithContext> {
    return this.subscribeTo("getTasks").pipe(map((t: TaskWithContext[]) => t.find(
      a => a.task.taskId === this.taskWithContext.task.taskId)));
  }

  protected openedInClusterDetails = () => !!this.taskWithContext.cluster;
}
