import {Component, Input, OnInit} from '@angular/core';
import {View} from '../../../common/view';
import {
  EncryptSecret,
  EnvironmentVariable,
  Secret,
  SetEnvironmentVariable
} from '@flux-capacitor-io/flux-host-typescriptmodels';
import {cloneObject, uuid} from '../../../common/utils';

@Component({
  selector: 'app-environment-variable-details',
  templateUrl: './environment-variable-details.component.html',
  styleUrls: ['./environment-variable-details.component.scss']
})
export class EnvironmentVariableDetailsComponent extends View implements OnInit {
  @Input() data: EnvironmentVariable;
  isNew: boolean;
  initialValue: string;

  ngOnInit(): void {
    this.initialValue = this.data.details.value;
    this.isNew = !this.data.variableId;
  }

  updateInfo = () => {
    this.sendQuery("getEntityId").subscribe(ownerId => {
      if (this.initialValue !== this.data.details.value && this.data.details.secret) {
        this.sendQuery("host.flux.service.common.api.EncryptSecret", <EncryptSecret>{secret: this.data.details.value}, {caching: false})
          .subscribe((secret: Secret) => {
              const details = cloneObject(this.data.details)
              details.value = secret.secret;
              this.sendCommand("host.flux.service.organisation.api.SetEnvironmentVariable", <SetEnvironmentVariable>{
                details: details,
                variableId: this.data.variableId || uuid(),
                ownerId: ownerId
              });
            }
          );
      } else {
        this.sendCommand("host.flux.service.organisation.api.SetEnvironmentVariable", <SetEnvironmentVariable>{
          details: this.data.details,
          variableId: this.data.variableId || uuid(),
          ownerId: ownerId
        });
      }
    });
  }
}
