import {Component, inject, Input} from '@angular/core';
import {Issue, IssueStatus} from "@flux-capacitor-io/flux-host-typescriptmodels";
import {ActivatedRoute, Router} from "@angular/router";
import {InjectorProvider} from "../../../../common/app-common-utils";
import {Location} from "@angular/common";
import {Handler} from "../../../../common/handler";
import {View} from "../../../../common/view";

@Component({
  selector: 'app-issue-overview-item',
  templateUrl: './issue-overview-item.component.html',
  styleUrls: ['./issue-overview-item.component.scss']
})
@Handler()
export class IssueOverviewItemComponent extends View {
  private location = inject(Location);
  private router: Router = inject(Router);
  private route: ActivatedRoute = inject(ActivatedRoute);

  @Input() issue: Issue;

  isResolved = (issue: Issue): boolean => IssueStatus.RESOLVED == issue.status;
  isMuted = (issue: Issue): boolean => IssueStatus.MUTED == issue.status;

  getErrorStatusConfig = (issue: Issue): IssueStatusConfig => {
    switch (issue.status) {
      case IssueStatus.RESOLVED:
        return {
          colorClass: "success",
          icon: "bi-check-circle"
        };
      case IssueStatus.MUTED:
        return {
          colorClass: "muted",
          icon: "bi-volume-mute"
        };
      default:
        return {
          colorClass: this.badgeColorForType(issue),
          icon: "bi-record-circle"
        };
    }
  }

  badgeColorForType = (issue: Issue) => {
    if (issue.status === IssueStatus.RESOLVED) {
      return "light";
    }
    switch (issue.details.level) {
      case "ERROR":
        return "danger";
      case "WARNING":
        return "warning";
      default:
        return "info";
    }
  }

  handleIssueClick(event: MouseEvent, issueId: string) {
    event.stopPropagation();
    if (issueId === this.route.snapshot.queryParamMap.get("issueId")) {
      this.sendCommandAndForget("openIssueDetails", issueId);
      this.location.go(InjectorProvider.injector.get(Router).createUrlTree(["../issues"],
        {relativeTo: this.route, queryParams: {issueId: issueId}}).toString())
      return;
    }
    this.router.navigate(["../issues"], {
      relativeTo: this.route,
      queryParams: {
        issueId: issueId
      }
    });
  }
}

interface IssueStatusConfig {
  colorClass: string;
  icon: string;
}
