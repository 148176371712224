<div class="row gy-3">
  <div class="col-md-8">
    <div class="border mb-3 mx-0">
      <div class="flex-fill p-3 bg-light fw-bold notranslate">Issue ID</div>
      <div class="flex-fill p-3 text-break notranslate">{{ entry.issueId || "-" }}</div>
    </div>
    <div class="row gx-3">
      <div class="col-md-6">
        <div class="d-flex flex-column mb-3">
          <div class="d-flex flex-column border mx-0 mb-3">
            <div class="flex-fill p-3 bg-light fw-bold notranslate">Error message</div>
            <div class="flex-fill p-3 text-break notranslate">{{ entry.details.errorMessage || "-" }}</div>
          </div>
          <div class="d-flex flex-column border mx-0">
            <div class="flex-fill p-3 bg-light fw-bold notranslate">Error</div>
            <div class="flex-fill p-3 text-break notranslate">
              <a [href]="getKibanaUrl(entry.details.error)" target="_blank">{{ entry.details.error || "-" }}</a>
            </div>
          </div>
        </div>
      </div>
      <div class="col-md-6">
        <div class="d-flex flex-column mb-3">
          <div class="d-flex border row mx-0 mb-3">
            <div class="col-3 flex-fill p-3 bg-light fw-bold notranslate">Level</div>
            <div class="col flex-fill p-3 notranslate">{{ formattedLevel || "-" }}</div>
          </div>
          <div class="d-flex border row mx-0">
            <div class="col-3 flex-fill p-3 bg-light fw-bold notranslate">Message</div>
            <div class="col flex-fill p-3 text-break notranslate">{{ entry.details.message || "-" }}</div>
          </div>
        </div>
      </div>
    </div>
    <div class="border d-flex mx-0">
      <div class="overflow-auto w-100">
        <div class="row bg-light fw-bold p-3">
          <div class="col-auto notranslate">
            Stack trace
          </div>
          <div class="col-auto ms-auto">
            <div class="form-check form-switch">
              <input class="form-check-input" type="checkbox" role="switch" id="applicationOnly"
                     [value]="isFullStackTraceDisplayed" (click)="toggleStackTrace()">
              <label class="form-check-label" for="applicationOnly">Application only</label>
            </div>
          </div>
        </div>
        <div class="col flex-fill p-3 notranslate stack-trace">
          {{ isFullStackTraceDisplayed ? entry.details.stackTrace : getStackTrace() }}
        </div>
      </div>
    </div>
  </div>
  <div class="col-md-4">
    <div class="row gy-3">
      <div class="col-12">
        <div class="d-flex flex-column border">
          <div class="flex-fill p-3 bg-light fw-bold notranslate">Message Index</div>
          <div class="flex-fill p-3 text-break notranslate">
            <a [href]="getKibanaUrl(entry.metadata.messageIndex)" target="_blank">
              {{ entry.metadata.messageIndex || "-" }}
            </a>
          </div>
        </div>
      </div>
      <div class="col-12">
        <div class="d-flex flex-column border">
          <div class="flex-fill p-3 bg-light fw-bold notranslate">Message ID</div>
          <div class="flex-fill p-3 text-break notranslate">
            <a [href]="getKibanaUrl(entry.metadata.messageId)" target="_blank">
              {{ entry.metadata.messageId || "-" }}
            </a>
          </div>
        </div>
      </div>
      <div class="col-12">
        <div class="d-flex flex-column border">
          <div class="flex-fill p-3 bg-light fw-bold notranslate">Invocation ID</div>
          <div class="flex-fill p-3 text-break notranslate">
            <a [href]="getKibanaUrl(entry.metadata.invocationId)" target="_blank">
              {{ entry.metadata.invocationId || "-" }}
            </a>
          </div>
        </div>
      </div>
      <div class="col-12">
        <div class="d-flex flex-column border">
          <div class="flex-fill p-3 bg-light fw-bold notranslate">Correlation ID</div>
          <div class="flex-fill p-3 text-break notranslate">
            <a [href]="getKibanaUrl(entry.metadata.correlationId)" target="_blank">
              {{ entry.metadata.correlationId || "-" }}
            </a>
          </div>
        </div>
      </div>
      <div class="col-12">
        <div class="d-flex flex-column border">
          <div class="flex-fill p-3 bg-light fw-bold notranslate">Trace ID</div>
          <div class="flex-fill p-3 text-break notranslate">
            <a [href]="getKibanaUrl(entry.metadata.traceId)" target="_blank">
              {{ entry.metadata.traceId || "-" }}
            </a>
          </div>
        </div>
      </div>
      <div class="col-12">
        <div class="d-flex flex-column border">
          <div class="flex-fill p-3 bg-light fw-bold notranslate">Client ID</div>
          <div class="flex-fill p-3 text-break notranslate">
            <a [href]="getKibanaUrl(entry.metadata.clientId)" target="_blank">
              {{ entry.metadata.clientId || "-" }}
            </a>
          </div>
        </div>
      </div>
      <div class="col-12">
        <div class="d-flex flex-column border">
          <div class="flex-fill p-3 bg-light fw-bold notranslate">Trace Element</div>
          <div class="flex-fill p-3 text-break notranslate">
            <a [href]="getKibanaUrl(entry.metadata.traceElement)" target="_blank">
              {{ entry.metadata.traceElement || "-" }}
            </a>
          </div>
        </div>
      </div>
      <div class="col-12">
        <div class="d-flex flex-column border">
          <div class="flex-fill p-3 bg-light fw-bold notranslate">Logger name</div>
          <div class="flex-fill p-3 text-break notranslate">
            <a [href]="getKibanaUrl(entry.details.loggerName)" target="_blank">
              {{ entry.details.loggerName || "-" }}
            </a>
          </div>
        </div>
      </div>
      <div class="col-12">
        <div class="d-flex flex-column border">
          <div class="flex-fill p-3 bg-light fw-bold notranslate">Trigger</div>
          <div class="flex-fill p-3 text-break notranslate">
            <a [href]="getKibanaUrl(entry.details.trigger)" target="_blank">
              {{ entry.details.trigger || "-" }}
            </a>
          </div>
        </div>
      </div>
      <div class="col-12">
        <div class="d-flex flex-column border">
          <div class="flex-fill p-3 bg-light fw-bold notranslate">Trigger type</div>
          <div class="flex-fill p-3 text-break notranslate">
            {{ entry.details.triggerType || "-" }}
          </div>
        </div>
      </div>
      <div class="col-12">
        <div class="d-flex flex-column border">
          <div class="flex-fill p-3 bg-light fw-bold notranslate">Consumer</div>
          <div class="flex-fill p-3 text-break notranslate">
            <a [href]="getKibanaUrl(entry.metadata.consumer)" target="_blank">
              {{ entry.metadata.consumer || "-" }}
            </a>
          </div>
        </div>
      </div>
      <div class="col-12">
        <div class="d-flex flex-column border">
          <div class="flex-fill p-3 bg-light fw-bold notranslate">Message Template</div>
          <div class="flex-fill p-3 text-break notranslate">
            <a [href]="getKibanaUrl(entry.details.messageTemplate)" target="_blank">
              {{ entry.details.messageTemplate || "-" }}
            </a>
          </div>
        </div>
      </div>
      <div class="col-12">
        <div class="d-flex flex-column border">
          <div class="flex-fill p-3 bg-light fw-bold notranslate">Sequence</div>
          <div class="flex-fill p-3 text-break notranslate">{{ entry.sequence || "-" }}</div>
        </div>
      </div>
      <div class="col-12">
        <div class="d-flex flex-column border">
          <div class="flex-fill p-3 bg-light fw-bold notranslate">Application</div>
          <div class="flex-fill p-3 text-break notranslate">
            <a [href]="getKibanaUrl(entry.details.application)" target="_blank">
              {{ entry.details.application || "-" }}
            </a>
          </div>
        </div>
      </div>
      <div class="col-12">
        <div class="d-flex flex-column border">
          <div class="flex-fill p-3 bg-light fw-bold notranslate">Environment</div>
          <div class="flex-fill p-3 text-break notranslate">{{ formattedEnvironment || "-" }}</div>
        </div>
      </div>
      <div class="col-12">
        <div class="d-flex flex-column border">
          <div class="flex-fill p-3 bg-light fw-bold notranslate ">Version ID</div>
          <div class="flex-fill p-3 text-break notranslate ">{{ entry.metadata.versionId || "-" }}</div>
        </div>
      </div>
    </div>
  </div>
</div>
