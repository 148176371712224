<div class="card hover-show w-100">
  <div class="card-body mb-3">
    <h2 class="card-title text-center notranslate">
      <i class="bi bi-diagram-3 me-2"></i>
      <a class="text-black hover-underline" [routerLink]="'organisation/' + organisation.organisationId">
        <span class="me-2">{{organisation.details.name}}</span>
      </a>
    </h2>
    <hr class="card-divider m-0 mb-3">
    <div class="list-group-flush list-group overflow-y-auto overflow-x-hidden" [style.max-height.px]="200">
      <ng-container *ngFor="let cluster of clusters; trackBy: trackByClusterId">
        <app-organisation-overview-item-cluster [organisation]="organisation" [cluster]="cluster"
                                                class="list-group-item py-3 position-relative"></app-organisation-overview-item-cluster>
      </ng-container>

      <div class="list-group-item py-3 pointer hover-underline" (click)="configureCluster()">
        <div class="row">
          <div class="col-auto">
            <i class="bi me-2 bi-plus-circle"></i>
          </div>
          <div class="col text-truncate"><small>Add new cluster</small></div>
        </div>
      </div>
    </div>
  </div>
</div>
