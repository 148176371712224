<div class="row justify-content-between align-items-center gy-4">
  <div class="col col-md-9 col-xl-7">
    <h2 class="mb-2">
      <i class="bi bi-shield-lock me-2"></i>
      <span>Change your password</span>
    </h2>
    <p class="text-muted mb-xl-0">
      We will email you to change your password, so please expect that email after submitting.
    </p>
  </div>
  <div class="col-auto ms-auto d-flex flex-row align-items-center">
    <button (click)="changePassword()" class="btn btn-white">
      <span>Request new password</span>
    </button>
  </div>
  <app-passkey-overview></app-passkey-overview>
</div>
