import {Component, inject} from '@angular/core';
import {Handler} from "../../../common/handler";
import {View} from "../../../common/view";
import {ActivatedRoute, Router} from "@angular/router";
import {HandleQuery} from "../../../common/handle-query";
import {Observable, of} from "rxjs";
import {Application, Cluster, GetUsage, InstantRange, TaskUsageEntry} from "@flux-capacitor-io/flux-host-typescriptmodels";
import {map, tap} from "rxjs/operators";
import {TaskWithContext} from "../../clusters/cluster-routing/cluster-routing.component";
import {AppCommonUtils} from "../../../common/app-common-utils";

@Component({
  selector: 'app-application-routing',
  templateUrl: './application-routing.component.html',
  styleUrls: ['./application-routing.component.scss']
})
@Handler()
export class ApplicationRoutingComponent extends View {
  private route = inject(ActivatedRoute);
  private router = inject(Router);
  private applicationId: string;

  constructor() {
    super();
    this.route.params.subscribe(params => this.applicationId = params["applicationId"]);
  }

  @HandleQuery("getApplication")
  getApplication(): Observable<Application> {
    return this.subscribeTo("getCluster")
      .pipe(map((cluster: Cluster) => cluster.applications.find(a => a.applicationId === this.applicationId)))
      .pipe(tap(a => {
        if (!a) {
          AppCommonUtils.registerInfo("Application has been destroyed");
          this.router.navigate(["../../"], {relativeTo: this.route});
        }
      }));
  }

  @HandleQuery("getEntityId")
  getEntityId(): Observable<string> {
    return this.subscribeTo("getApplication").pipe(map((a: Application) => a.applicationId));
  }

  @HandleQuery("getEntity")
  getEntity(): Observable<any> {
    return this.subscribeTo("getApplication");
  }

  @HandleQuery("getTask")
  getTask(taskId?: string): Observable<TaskWithContext> {
    return taskId ? this.subscribeTo("getApplication")
        .pipe(map((a: Application) => (<TaskWithContext>{
          application: a,
          task: a.tasks.find(t => t.taskId === taskId)
        })))
      : of(null);
  }

  @HandleQuery("getTasks")
  getTasks(): Observable<TaskWithContext[]> {
    return this.subscribeTo("getApplication").pipe(map((a: Application) => a.tasks.map(t => (<TaskWithContext>{
      task: t,
      application: a
    }))));
  }

  @HandleQuery("getUsage")
  getUsage(timeRange: InstantRange): Observable<TaskUsageEntry[]> {
    return this.sendQuery("host.flux.service.usage.api.GetUsage", <GetUsage>{
      timeRange: timeRange,
      facetFilters: [{
        facetName: "applicationId",
        values: [this.applicationId]
      }]
    });
  }
}
