import {inject, Injectable} from '@angular/core';
import {AuthConfig, OAuthService} from 'angular-oauth2-oidc';
import {BehaviorSubject, from, Observable} from 'rxjs';

import {StatehandlerService} from './statehandler.service';
import {StandaloneHandler} from "../common/standalone-handler";
import {CookieService} from "ngx-cookie";
import {StorageService} from "./storage.service";

@Injectable({
  providedIn: 'root',
})
export class AuthenticationService extends StandaloneHandler {
  private _authenticated: boolean = false;
  private readonly _authenticationChanged: BehaviorSubject<boolean> = new BehaviorSubject(this.authenticated);
  private oauthService: OAuthService = inject(OAuthService);
  private authConfig: AuthConfig = inject(AuthConfig);
  private statehandler: StatehandlerService = inject(StatehandlerService);
  private cookieService: CookieService = inject(CookieService);
  private authStorage: StorageService = inject(StorageService);

  constructor() {
    super();
    this._authenticationChanged.subscribe(a => this.updateCookie(a));
  }

  public get authenticated(): boolean {
    return this._authenticated;
  }

  public get authenticationChanged(): Observable<boolean> {
    return this._authenticationChanged;
  }

  public getOIDCUser(): Observable<any> {
    return from(this.oauthService.loadUserProfile());
  }

  public async authenticate(setState: boolean = true): Promise<boolean> {
    this.oauthService.configure(this.authConfig);
    this.oauthService.setupAutomaticSilentRefresh();

    this.oauthService.strictDiscoveryDocumentValidation = false;
    await this.oauthService.loadDiscoveryDocumentAndTryLogin();

    this._authenticated = this.oauthService.hasValidAccessToken();

    if (!this.oauthService.hasValidIdToken() || !this.authenticated) {
      const newState = setState ? await this.statehandler.createState().toPromise() : undefined;
      this.oauthService.initCodeFlow(newState);
    }
    this._authenticationChanged.next(this.authenticated);

    return this.authenticated;
  }

  public signout(): void {
    this.oauthService.logOut();
    this._authenticated = false;
    this._authenticationChanged.next(false);
  }

  private updateCookie(authenticated: boolean) {
    if (!authenticated) {
      this.cookieService.remove("fluxhostjwt");
    } else {
      this.cookieService.put("fluxhostjwt", this.authStorage.getItem("id_token"), {
        expires: this.authStorage.getItem("id_token_expires_at"),
        sameSite: true,
        secure: true
      });
    }
  }
}
