<app-overview [query]="query" (dataChange)="data = $event" [windowScroll]="true">
  <div class="header-bar align-items-center row mb-4">
    <div class="col">
      <app-filter [(filterTerm)]="term" (onUpdate)="executeQuery()"
                  localStorageKey="task-overview-term" placeholder="Search"></app-filter>
    </div>
  </div>
  <div class="overview-items">
    <div class="row mx-0">
      <ng-container *ngFor="let task of data; trackBy: trackByForRecord">
        <div class="col-12 col-md-4 d-flex pb-4">
          <app-task-overview-item class="w-100 h-100 d-flex" [taskWithContext]="task"></app-task-overview-item>
        </div>
      </ng-container>
    </div>
  </div>
  <div class="empty-container col-12 h-100 d-flex align-items-center justify-content-center flex-column">
    <span class="h1 mb-2">No tasks found</span>
    <span class="text-secondary">Try adjusting your filters or search term</span>
  </div>
</app-overview>
