import {Component, Input, OnInit} from '@angular/core';
import {View} from '../../../../common/view';
import {
  DecryptEnvironmentVariable,
  DeleteEnvironmentVariable,
  EnvironmentVariable
} from '@flux-capacitor-io/flux-host-typescriptmodels';
import {
  EnvironmentVariableDetailsComponent
} from '../../environment-variable-details/environment-variable-details.component';
import {cloneObject} from '../../../../common/utils';
import {initial} from 'lodash';

@Component({
  selector: 'app-environment-variable-overview-item',
  templateUrl: './environment-variable-overview-item.component.html',
  styleUrls: ['./environment-variable-overview-item.component.scss']
})
export class EnvironmentVariableOverviewItemComponent extends View implements OnInit {
  original : EnvironmentVariable;
  @Input() variable: EnvironmentVariable;

  ngOnInit(): void {
    this.original = this.variable;
  }

  delete = () => this.sendCommand("host.flux.service.organisation.api.DeleteEnvironmentVariable", <DeleteEnvironmentVariable>{
    variableId: this.variable.variableId
  });

  open = () => this.openModal(EnvironmentVariableDetailsComponent, cloneObject(this.original));

  showSecret = () => this.sendQuery('host.flux.service.organisation.api.DecryptEnvironmentVariable',
    <DecryptEnvironmentVariable> { variableId: this.variable.variableId }, {caching: false})
    .subscribe(decrypted => this.variable = decrypted);
  protected readonly initial = initial;

  hideSecret() {
    this.variable = this.original;
  }
}
