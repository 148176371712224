<div class="row justify-content-center">
  <div class="col-12 col-lg-10 col-xl-8">
    <div class="header mt-md-5">
      <div class="header-body">
        <div class="row align-items-center">
          <div class="col-auto">
            <span class="h1 m-0"><i class="bi bi-person"></i></span>
          </div>
          <div class="col">
            <h1 class="header-title text-truncate notranslate">Account</h1>
          </div>
        </div> <!-- / .row -->
        <div class="row align-items-center">
          <div class="col">
            <ul class="nav nav-tabs nav-overflow header-tabs">
              <li class="nav-item">
                <a routerLink="/profile" class="nav-link">General</a>
              </li>
              <li class="nav-item">
                <a routerLink="/profile/settings" class="nav-link">Security</a>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
    <router-outlet></router-outlet>
  </div>
</div>
