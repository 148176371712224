<app-form (submit)="updateInfo()">
  <div class="card">
    <div class="card-header">
      <h3 class="card-header-title">
        <i class="bi bi-window me-2"></i>
        <span>{{isNewRecord ? 'Add new application' : 'Application details'}}</span>
      </h3>
    </div>

    <div class="card-body">
      <div class="row">
        <div class="col-12 col-md-6">
          <app-form-field label="Cluster" [renderOptional]="false">
            <input [(ngModel)]="data.cluster.details.name" type="text" class="form-control" disabled>
          </app-form-field>
        </div>
        <div class="col-12 col-md-6">
          <app-form-field label="Application name">
            <input [(ngModel)]="data.application.details.name" type="text" class="form-control" required>
          </app-form-field>
        </div>
        <div class="col-12 col-md-6">
          <app-form-field label="Desired task count" [value]="data.application.details.taskCount">
            <input [(ngModel)]="data.application.details.taskCount" type="number" class="form-control" required [appDefaultValue]="1">
          </app-form-field>
        </div>
        <div class="col-12 col-md-6">
          <app-form-field label="Size" [value]="data.application.details.descriptor.size | sentenceCase">
            <app-select [(ngModel)]="data.application.details.descriptor.size" type="text" required
                        [optionsProvider]="taskSizes" [appDefaultValue]="'medium'"></app-select>
          </app-form-field>
        </div>
      </div>
    </div>

    <div class="card-footer">
      <div class="d-flex justify-content-between">
        <a class="btn btn-secondary" data-bs-dismiss="modal">Cancel</a>
        <button type="submit" class="btn btn-primary">Save changes</button>
      </div>
    </div>
  </div>
</app-form>
