import {Component, Input, OnInit} from '@angular/core';
import {Observable} from "rxjs";
import {Handler} from "../../../common/handler";
import {View} from "../../../common/view";
import {ExtendedAuthorisation} from "../user-overview/user-overview-item/user-overview-item.component";
import {GiveAuthorisation, Organisation, Role, UserProfile} from "@flux-capacitor-io/flux-host-typescriptmodels";
import {filterByTerm, uuid} from "../../../common/utils";
import {map} from "rxjs/operators";

@Component({
  selector: 'app-authorisation-details',
  templateUrl: './authorisation-details.component.html',
  styleUrls: ['./authorisation-details.component.scss']
})
@Handler()
export class AuthorisationDetailsComponent extends View implements OnInit {
  authorisationRoles: Role[] = [Role.owner, Role.manager, Role.deployer, Role.developer, Role.member];
  isNewAuthorisation: boolean;
  nominatorEditable: boolean;

  nominatorOrganisation: Organisation;
  nominee: UserProfile;

  organisations: Observable<Organisation[]>;

  @Input() data: ExtendedAuthorisation;

  ngOnInit(): void {
    this.organisations = this.subscribeTo("getOrganisations");
    if (!this.data.authorisation.authorisationId) {
      this.isNewAuthorisation = true;
      this.data.authorisation.authorisationId = uuid();
      this.nominatorEditable = !this.data.authorisation.nominator;
    } else {
      this.nominatorEditable = this.data.nominatorEditable;
    }
    this.data.authorisation.entityIds = this.data.authorisation.entityIds || [];
    if (this.data.authorisation.nominator) {
      this.subscribeTo("getOrganisation")
        .subscribe((o: Organisation) => this.nominatorOrganisation = o);
    }
    if (this.data.authorisation.nominee) {
      this.subscribeTo("getUser", this.data.authorisation.nominee)
        .subscribe((o: UserProfile) => this.nominee = o);
    }
    // this.sourceProvider = new OrganisationProvider(this.data.nominator,
    //     this.data.entityIds.length ? this.data.entityIds : []);
  }

  setNominee(user: UserProfile) {
    this.nominee = user;
    this.data.authorisation.nominee = user?.userId;
  }

  setNominator(organisation: Organisation) {
    this.nominatorOrganisation = organisation;
    this.data.authorisation.nominator = organisation?.organisationId;
    // this.sourceProvider = new OrganisationProvider(this.data.nominator,
    //     this.data.entityIds.length ? this.data.entityIds : []);
  }

  searchOrganisations = (term: string) => this.organisations.pipe(map(o => o.filter(filterByTerm(term))));

  organisationFormatter = (organisation: Organisation) => organisation.details.name;

  updateInfo = () => this.sendCommand("host.flux.service.organisation.api.GiveAuthorisation", <GiveAuthorisation>{
    organisationId: this.data.authorisation.nominator,
    authorisation: this.data.authorisation
  });

  authorisationHeader = (): string => `${this.nominee.details.firstName} ${this.nominee.details.lastName}`;

  // updateEntityIds(sources: SourceInfo[]) {
  //   const sourceIds = this.sourceProvider.sourceSelectionAfterCleanup();
  //   this.data.entityIds = sourceIds.locationIds.concat(sourceIds.connectionIds).concat(sourceIds.meterIds);
  // }
}
