import {Component, Input} from '@angular/core';
import {UsageEntry} from "@flux-capacitor-io/flux-host-typescriptmodels";

@Component({
  selector: 'app-usage-overview-item',
  templateUrl: './usage-overview-item.component.html',
  styleUrls: ['./usage-overview-item.component.scss']
})
export class UsageOverviewItemComponent {
  @Input() usageEntry: UsageEntry;
}
