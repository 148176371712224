<div class="card hover-show w-100">
  <div class="card-body mb-3">
    <h2 class="card-title text-center notranslate">
      <i class="bi bi-window-stack me-2"></i>
      <a class="text-black hover-underline" [routerLink]="'../cluster/' + cluster.clusterId">
        <span class="me-2">{{cluster.details.name}}</span>
      </a>
    </h2>
    <h5 class="text-muted text-center notranslate">
      <app-cluster-status [clusterStatusInfo]="clusterStatusInfo"></app-cluster-status>
      <span class="vertical-align-middle">{{cluster.status | sentenceCase}}</span>
    </h5>
    <hr class="card-divider m-0 mb-3">
    <div class="list-group-flush list-group overflow-y-auto overflow-x-hidden" [style.max-height.px]="200">
      <ng-container *ngFor="let application of cluster.applications; trackBy: trackByApplicationId">
        <app-cluster-overview-item-application [cluster]="cluster" [application]="application"
                                               class="list-group-item py-3 position-relative"></app-cluster-overview-item-application>
      </ng-container>
      <div class="list-group-item py-3 pointer hover-underline" (click)="configureApplication()">
        <div class="row">
          <div class="col-auto">
            <i class="bi me-2 bi-plus-circle"></i>
          </div>
          <div class="col text-truncate"><small>Add new application</small></div>
        </div>
      </div>
    </div>
  </div>
</div>
