<div class="row g-0">
  <ng-container *ngFor="let taskMapped of tasks | async; let first = first; let last = last">
    <ng-container *ngIf="taskMapped.color | async as color">
      <div class="col-auto d-flex align-items-stretch position-relative" [style.height.rem]="1.5"
           [style.width.%]="taskMapped.widthPercentage" [ngClass]="'bg-'+color + (first ? ' rounded-start ' : ' ') + (last ? ' rounded-end ' : ' ')"
           data-bs-toggle="tooltip" data-bs-placement="top" [title]="getTitleForTask(taskMapped)">
        <div *ngFor="let task of taskMapped.tasks; trackBy: trackByForTask"></div>
        <div class="position-absolute w-100 h-100 start-0 d-flex align-items-center justify-content-center">
          <span>{{ taskMapped.tasks.length }}</span>
        </div>
      </div>
    </ng-container>
  </ng-container>
</div>
