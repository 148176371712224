import {Component, inject, OnInit} from '@angular/core';
import {Organisation, Team} from "@flux-capacitor-io/flux-host-typescriptmodels";
import {Handler} from "../../../common/handler";
import {View} from "../../../common/view";
import {RouteData} from "../../../app-routing.module";
import {ActivatedRoute} from "@angular/router";
import {cloneDeep} from "lodash";
import {
  OrganisationDetailsComponentData,
  OrganisationDetailsModalComponent
} from "../organisation-details-modal/organisation-details-modal.component";

@Component({
  selector: 'app-organisation-details',
  templateUrl: './organisation-details.component.html',
  styleUrls: ['./organisation-details.component.scss']
})
@Handler()
export class OrganisationDetailsComponent extends View implements OnInit {
  private route: ActivatedRoute = inject(ActivatedRoute);
  organisation: Organisation;
  routeData: RouteData;
  team: Team;

  constructor() {
    super();
    this.routeData = this.route.snapshot.data as RouteData;
  }

  ngOnInit() {
    this.subscribeTo("getOrganisation").subscribe((o: Organisation) => this.organisation = o);
    this.subscribeTo("getTeam").subscribe((team: Team) => this.team = team);
  }

  configureOrganisation = () => this.openModal(OrganisationDetailsModalComponent, <OrganisationDetailsComponentData>{
    organisation: cloneDeep(this.organisation)
  });
}
