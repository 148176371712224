<app-overview [query]="passKeys" (dataChange)="data = $event" [windowScroll]="true">
  <div class="header-bar align-items-center row mb-4">
    <div class="col col-md-9 col-xl-7">
      <h2 class="mb-2">
        <i class="bi bi-fingerprint me-2"></i>
        <span>Passkeys</span>
      </h2>
      <span class="text-muted mb-xl-0">
        We will email you to configure your passkey, so please expect that email after submitting.
      </span>
    </div>
    <div class="col-auto ms-auto d-flex flex-row align-items-center">
      <button (click)="requestPasskey()" type="button" class="btn btn-white">
        <i class="bi bi-plus me-md-2"></i>
        <span class="d-none d-md-inline">Add passkey</span>
      </button>
    </div>
  </div>
  <div class="overview-items">
    <div class="row mx-0">
      <ng-container *ngFor="let record of data; trackBy: trackByForRecord">
        <div class="col-12 col-md-6 col-xxl-4">
          <app-passkey-overview-item class="h-100 d-flex" [record]="record"></app-passkey-overview-item>
        </div>
      </ng-container>
    </div>
  </div>
  <div class="empty-container col-12 h-100 d-flex align-items-center justify-content-center flex-column"></div>
</app-overview>
