<div class="card hover-show w-100">
  <div class="card-body mb-3">
    <h2 class="card-title notranslate">
      <button (click)="downloadInvoice()" data-bs-title="Download invoice" class="position-absolute btn" style="right: 1rem; top: 0.5rem">
        <i class="bi bi-download"></i>
      </button>
      <div class="text-center">
        <i class="bi bi-receipt me-2"></i>
        <a class="text-black">
          <span class="me-2">{{ invoice.invoiceNumber }}</span>
        </a>
      </div>
    </h2>
    <hr class="card-divider m-0 mb-3">
    <div class="list-group-flush list-group overflow-y-auto overflow-x-hidden" [style.max-height.px]="200">
      <div class="list-group-item py-3">
        <div class="row">
          <div class="col-auto">
            <i class="bi bi-clock me-2"></i>
          </div>
          <div class="col-4">
            <span class="text-black"><small>Invoice date</small></span>
          </div>
          <div class="col text-truncate notranslate d-inline-flex align-items-start">
            <a class="text-black"><small>{{ invoice.invoiceDate | timestamp: "DD-MM-YYYY" }}</small></a>
          </div>
        </div>
      </div>
      <div class="list-group-item py-3">
        <div class="row">
          <div class="col-auto">
            <i class="bi bi-clock me-2"></i>
          </div>
          <div class="col-4">
            <span class="text-black"><small>Due date</small></span>
          </div>
          <div class="col text-truncate notranslate d-inline-flex align-items-start">
            <a class="text-black"><small>{{ invoice.dueDate | timestamp: "DD-MM-YYYY" }}</small></a>
          </div>
        </div>
      </div>
      <div class="list-group-item py-3">
        <div class="row">
          <div class="col-auto">
            <i class="bi bi-currency-euro me-2"></i>
          </div>
          <div class="col-4">
            <span class="text-black"><small>Total (excl. VAT)</small></span>
          </div>
          <div class="col text-truncate notranslate d-inline-flex align-items-start">
            <a class="text-black"><small>{{ invoice.totalExcludingVat | number : '1.2-2' }}</small></a>
          </div>
        </div>
      </div>
      <div class="list-group-item py-3">
        <div class="row">
          <div class="col-auto">
            <i class="bi bi-currency-euro me-2"></i>
          </div>
          <div class="col-4">
            <span class="text-black"><small>Total (incl. VAT)</small></span>
          </div>
          <div class="col text-truncate notranslate d-inline-flex align-items-start">
            <a class="text-black"><small>{{ invoice.totalIncludingVat | number : '1.2-2' }}</small></a>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
