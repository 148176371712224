<ul class="dropdown-menu" *ngIf="issue">
  <li *ngIf="issue.status === 'RESOLVED'; else notResolved">
    <button class="dropdown-item" (click)="reopenIssue(issue)">Reopen</button>
  </li>
  <ng-template #notResolved>
    <li>
      <button class="dropdown-item" (click)="resolveIssue(issue)">Resolve</button>
    </li>
    <li *ngIf="issue.status === 'MUTED' else unmuted">
      <button class="dropdown-item" (click)="unmuteIssue(issue)">Unmute errors</button>
    </li>
  </ng-template>
  <ng-template #unmuted>
    <li>
      <button class="dropdown-item" (click)="setMuteDeadline('1d')">Mute for 1 day</button>
    </li>
    <li>
      <button class="dropdown-item" (click)="setMuteDeadline('1w')">Mute for 1 week</button>
    </li>
    <li>
      <button class="dropdown-item" (click)="setMuteDeadline('1m')">Mute for 1 month</button>
    </li>
    <li>
      <button class="dropdown-item" (click)="setMuteDeadline('indefinite')">Mute indefinitely</button>
    </li>
  </ng-template>
</ul>
