import {AfterViewInit, Component, OnInit} from '@angular/core';
import {Handler} from "../../../common/handler";
import {View} from "../../../common/view";
import {Application, Cluster} from "@flux-capacitor-io/flux-host-typescriptmodels";
import {ComparatorChain} from "../../../common/comparator-chain";
import {Observable} from "rxjs";
import {GetApplications} from "../../../app.component";
import {map} from "rxjs/operators";
import {filterByTerm, sort} from "../../../common/utils";
import {
  ApplicationDetailsModalComponent,
  ApplicationInfoDetailsComponentData
} from "../application-details-modal/application-details-modal.component";

@Component({
  selector: 'app-application-overview',
  templateUrl: './application-overview.component.html',
  styleUrls: ['./application-overview.component.scss']
})
@Handler()
export class ApplicationOverviewComponent extends View implements OnInit, AfterViewInit {
  private comparatorChain = new ComparatorChain("details.name", "applicationId");

  cluster: Cluster;

  term: string;
  query: Observable<Application[]>;
  data: Application[] = [];

  ngOnInit() {
    this.subscribeTo("getCluster").subscribe(c => this.cluster = c);
  }

  ngAfterViewInit(): void {
    this.executeQuery();
  }

  createApplication = () => this.openModal(ApplicationDetailsModalComponent, <ApplicationInfoDetailsComponentData>{
    cluster: this.cluster,
    application: null
  });

  executeQuery() {
    this.query = this.subscribeTo("getApplications", <GetApplications> {clusterId: this.cluster.clusterId})
      .pipe(map((o: Application[]) => o.filter(filterByTerm(this.term))))
      .pipe(map(c => sort(c, this.comparatorChain)));
  }

  trackByForRecord(index: number, record: Application) {
    return record.applicationId;
  }
}
