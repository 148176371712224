import {Component, Input} from '@angular/core';
import {IssueEntry} from "@flux-capacitor-io/flux-host-typescriptmodels";
import {RefdataUtils} from "../../../utils/refdata.utils";

@Component({
  selector: 'app-issue-entry',
  templateUrl: './issue-entry.component.html',
  styleUrls: ['./issue-entry.component.scss']
})
export class IssueEntryComponent {
  @Input() entry: IssueEntry;

  formattedLevel: string;
  formattedEnvironment: string;
  isFullStackTraceDisplayed: boolean = true;

  ngOnInit(): void {
    this.formatEnumValues();
  }

  toggleStackTrace() {
    this.isFullStackTraceDisplayed = !this.isFullStackTraceDisplayed;
  }

  getStackTrace(): string {
    if (this.isFullStackTraceDisplayed) {
      return this.entry.details.stackTrace;
    } else {
      return this.entry.details.stackTrace
        .split('\n')
        .filter(line => !line.trim().startsWith('at host.flux.'))
        .join('\n');
    }
  }

  formatEnumValues(): void {
    this.formattedLevel = RefdataUtils.enumFormatter(this.entry.details.level);
    this.formattedEnvironment = RefdataUtils.enumFormatter(this.entry.details.environment);
  }

  getKibanaUrl(fieldValue: string): string {
    return "";
  }
}

