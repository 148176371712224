import {Component, OnInit} from '@angular/core';
import {Handler} from "../../../common/handler";
import {View} from "../../../common/view";
import {Observable, of} from "rxjs";
import {Task, TaskStatus} from "@flux-capacitor-io/flux-host-typescriptmodels";
import {map, tap} from "rxjs/operators";
import {lodash} from 'src/app/common/utils';
import {TaskWithContext} from "../../clusters/cluster-routing/cluster-routing.component";

@Component({
  selector: 'app-task-summary',
  templateUrl: './task-summary.component.html',
  styleUrls: ['./task-summary.component.scss']
})
@Handler()
export class TaskSummaryComponent extends View implements OnInit {
  tasks: Observable<TaskMapped[]>;
  sortedTaskStatuses: TaskStatus[] = [TaskStatus.launching, TaskStatus.running, TaskStatus.destroying, TaskStatus.destroyed];

  cachedColors: Map<TaskStatus, string> = new Map();

  getColor = (status: TaskStatus): Observable<string> => this.cachedColors.has(status)
    ? of(this.cachedColors.get(status))
    : this.sendQuery("getTaskStatusColor", status).pipe(tap(c => this.cachedColors.set(status, c)));

  ngOnInit(): void {
    this.tasks = this.subscribeTo("getTasks")
      .pipe(map((tasks: TaskWithContext[]) => Object.entries(lodash.groupBy(tasks, t => t.task.status))))
      .pipe(map(d => d.map(([status, tasks]) => {
        const total = lodash.sum(d.flatMap(s => s[1].length));
        return <TaskMapped>{
          status: status,
          tasks: tasks,
          color: this.getColor(status as TaskStatus),
          widthPercentage: (tasks.length / total) * 100
        };
      })))
      .pipe(map(tasks => lodash.sortBy(tasks, t => this.sortedTaskStatuses.indexOf(t.status))));
  }

  trackByForTask(index: number, record: Task) {
    return record.taskId;
  }

  getTitleForTask = (task: TaskMapped): string => `${task.tasks.length} task${task.tasks.length > 1 ? 's are' : ' is'} ${task.status}`;
}

interface TaskMapped {
  status: TaskStatus;
  tasks: Task[];
  color: Observable<string>;
  widthPercentage: number;
}
