<div class="navbar-user d-flex row" id="sidebarUser">
  <!-- Brand -->
  <a class="navbar-brand col-auto" [routerLink]="'/'">
    <img src="/assets/images/logos/flux-host-logo.png" class="navbar-brand-img mx-auto" alt="Logo"
         style="max-height: 3rem">
  </a>

  <div class="col">
    <app-breadcrumb></app-breadcrumb>
  </div>

  <!-- Dropup -->
  <div class="dropdown col-auto">

    <!-- Toggle -->
    <a href="#" id="avatar-button" class="dropdown-toggle notranslate" role="button"
       data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
      <div class="avatar avatar-sm avatar-online">
        <span class="avatar-title rounded-circle">{{ appContext.initials }}</span>
      </div>
    </a>

    <!-- Menu -->
    <div class="dropdown-menu dropdown-menu-end" aria-labelledby="avatar-button">
      <a routerLink="/profile" class="dropdown-item">Profile</a>
      <button *ngIf="appContext.isAdmin()" (click)="openEncrypt()" class="dropdown-item">Encrypt secret</button>
      <button *ngIf="appContext.impersonator" class="dropdown-item" (click)="stopImpersonating()">
        Stop impersonating
      </button>
      <hr class="dropdown-divider">
      <button class="dropdown-item" (click)="signOut()">Logout</button>
    </div>
  </div>
</div>
