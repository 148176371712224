import {NgModule} from '@angular/core';
import {ActivatedRoute, ActivatedRouteSnapshot, Route, RouterModule} from '@angular/router';
import {HomeComponent} from "./views/home/home.component";
import {ClusterDetailsComponent} from "./views/clusters/cluster-details/cluster-details.component";
import {ApplicationDetailsComponent} from "./views/applications/application-details/application-details.component";
import {Observable} from "rxjs";
import {subscribeTo} from "./common/app-common-utils";
import {Organisation} from "@flux-capacitor-io/flux-host-typescriptmodels";
import {map} from "rxjs/operators";
import {ApplicationRoutingComponent} from "./views/applications/application-routing/application-routing.component";
import {ClusterRoutingComponent} from "./views/clusters/cluster-routing/cluster-routing.component";
import {EditProfileComponent} from "./views/edit-profile/edit-profile.component";
import {EditProfileGeneralComponent} from "./views/edit-profile/edit-profile-general/edit-profile-general.component";
import {OrganisationRoutingComponent} from "./views/organisations/organisation-routing/organisation-routing.component";
import {OrganisationDetailsComponent} from "./views/organisations/organisation-details/organisation-details.component";
import {
  OrganisationOverviewComponent
} from "./views/organisations/organisation-overview/organisation-overview.component";
import {AuthGuard} from "./authentication/auth.guard";
import {EditProfileSecurityComponent} from "./views/edit-profile/edit-profile-security/edit-profile-security.component";

const organisationBreadcrumbQuery = (routeParams) => subscribeTo("getOrganisations")
  .pipe(map((o: Organisation[]) => o.find(c => c.organisationId === routeParams.organisationId)))
  .pipe(map(o => o.details.name));

const clusterBreadcrumbQuery = (routeParams) => subscribeTo("getOrganisations")
  .pipe(map((os: Organisation[]) => os.find(o => o.teams.some(t => t.clusters.some(c => c.clusterId === routeParams.clusterId)))))
  .pipe(map(o => ({
    organisation: o,
    cluster: o.teams.flatMap(t => t.clusters).find(c => c.clusterId === routeParams.clusterId)
  })));

const applicationBreadcrumbQuery = (routeParams) => subscribeTo("getOrganisations")
  .pipe(map((os: Organisation[]) => {
    const organisation = os.find(o => o.teams.some(t => t.clusters.some(
      c => c.applications.some(a => a.applicationId === routeParams.applicationId))));
    return {
      organisation: organisation,
      cluster: organisation.teams.flatMap(t => t.clusters).find(c => c.applications
        .some(a => a.applicationId === routeParams.applicationId))
    };
  }))
  .pipe(map(o => {
    return {
      organisation: o.organisation,
      cluster: o.cluster,
      application: o.cluster.applications.find(a => a.applicationId === routeParams.applicationId)
    };
  }));

const routes: FluxHostRoute[] = [
  {
    title: "Home",
    path: '',
    component: HomeComponent,
    canActivate: [AuthGuard],
    children: [
      {
        title: "Dashboard",
        path: '',
        component: OrganisationOverviewComponent,
      },
      {
        title: (route) => organisationBreadcrumbQuery(route.params),
        path: 'organisation/:organisationId',
        component: OrganisationRoutingComponent,
        data: {
          breadcrumbLabel: organisationBreadcrumbQuery,
        },
        children: [
          {path: 'clusters', component: OrganisationDetailsComponent, data: {activeTabIndex: 0}},
          {path: 'users', component: OrganisationDetailsComponent, data: {activeTabIndex: 1}},
          {path: 'api-keys', component: OrganisationDetailsComponent, data: {activeTabIndex: 2}},
          {path: 'variables', component: OrganisationDetailsComponent, data: {activeTabIndex: 3}},
          {path: 'usage', component: OrganisationDetailsComponent, data: {activeTabIndex: 4}},
          {path: 'history', component: OrganisationDetailsComponent, data: {activeTabIndex: 5}},
          {path: 'invoices', component: OrganisationDetailsComponent, data: {activeTabIndex: 6}},
          {
            title: (route) => clusterBreadcrumbQuery(route.params)
              .pipe(map(a => `${a.cluster.details.name} – ${a.organisation.details.name}`)),
            path: 'cluster/:clusterId',
            component: ClusterRoutingComponent,
            data: {
              breadcrumbLabel: (routeParams) => clusterBreadcrumbQuery(routeParams)
                .pipe(map(c => c.cluster.details.name))
            },
            children: [
              {path: 'applications', component: ClusterDetailsComponent, data: {activeTabIndex: 0}},
              {path: 'tasks', component: ClusterDetailsComponent, data: {activeTabIndex: 1}},
              {path: 'variables', component: ClusterDetailsComponent, data: {activeTabIndex: 2}},
              {path: 'usage', component: ClusterDetailsComponent, data: {activeTabIndex: 3}},
              // {path: 'issues', component: ClusterDetailsComponent, data: {activeTabIndex: 1}},
              {path: 'history', component: ClusterDetailsComponent, data: {activeTabIndex: 4}},
              {path: 'logs', component: ClusterDetailsComponent, data: {activeTabIndex: 5}},
              {
                title: (route) => applicationBreadcrumbQuery(route.params)
                  .pipe(map(a => `${a.application.details.name} – ${a.cluster.details.name} – ${a.organisation.details.name}`)),
                path: 'application/:applicationId',
                component: ApplicationRoutingComponent,
                data: {
                  breadcrumbLabel: (routeParams) => applicationBreadcrumbQuery(routeParams)
                    .pipe(map(a => a.application.details.name)),
                },
                children: [
                  {path: 'tasks', component: ApplicationDetailsComponent, data: {activeTabIndex: 0}},
                  {path: 'variables', component: ApplicationDetailsComponent, data: {activeTabIndex: 1}},
                  {path: 'usage', component: ApplicationDetailsComponent, data: {activeTabIndex: 2}},
                  // {path: 'issues', component: ApplicationDetailsComponent, data: {activeTabIndex: 1}},
                  {path: 'history', component: ApplicationDetailsComponent, data: {activeTabIndex: 3}},
                  {path: 'logs', component: ApplicationDetailsComponent, data: {activeTabIndex: 4}},
                  {path: '**', redirectTo: 'tasks'}
                ]
              },
              {path: '**', redirectTo: 'applications'}
            ]
          },
          {path: '**', redirectTo: 'clusters'}
        ]
      },
      {
        path: 'profile',
        component: EditProfileComponent,
        children: [
          {path: "", component: EditProfileGeneralComponent},
          {path: "settings", component: EditProfileSecurityComponent}
        ]
      }
    ]
  },
  {
    path: 'auth/callback',
    redirectTo: ''
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule {
}

export interface FluxHostRoute extends Route {
  data?: RouteData;
  children?: FluxHostRoute[];
}

export interface ActivatedFluxRoute extends ActivatedRoute {
  data: Observable<RouteData>;
  snapshot: ActivatedFluxRouteSnapshot;
}

export interface ActivatedFluxRouteSnapshot extends ActivatedRouteSnapshot {
  data: RouteData;
}

export interface RouteData {
  breadcrumbLabel?: (routeParams: any) => Observable<string>;
  activeTabIndex?: number;
}
