import {AfterViewInit, Component} from '@angular/core';
import {View} from "../../../common/view";
import {Observable} from "rxjs";
import {GetUsers, UserProfile} from "@flux-capacitor-io/flux-host-typescriptmodels";
import {map} from "rxjs/operators";
import {Handler} from "../../../common/handler";
import {ComparatorChain} from "../../../common/comparator-chain";
import {UserDetailsComponent} from "../user-details/user-details.component";
import {sort} from '../../../common/utils';

@Component({
  selector: 'app-user-overview',
  templateUrl: './user-overview.component.html',
  styleUrls: ['./user-overview.component.scss']
})
@Handler()
export class UserOverviewComponent extends View implements AfterViewInit {
  private userComparator = new ComparatorChain("details.lastName", "details.firstName", "userId");
  term: string;
  query: Observable<UserProfile[]>;
  data: UserProfile[] = [];

  ngAfterViewInit(): void {
    this.executeQuery();
  }

  create = () => this.openModal(UserDetailsComponent, <UserProfile> {
    details: {},
    receivedAuthorisations: [],
    systemUser: false
  });

  executeQuery() {
    this.query = this.subscribeTo("getUsers", <GetUsers>{term: this.term})
      .pipe(map((u: UserProfile[]) => sort(u, this.userComparator)));
  }

  trackByForRecord(index: number, record: UserProfile) {
    return record.userId;
  }
}
