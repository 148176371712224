import {
  AfterViewInit, ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  inject,
  Input,
  NgZone,
  Output,
  ViewChild
} from '@angular/core';
import lodash, {Dictionary} from "lodash";
import {FacetValueResultFormatted} from "./facet-filter-value/facet-filter-value.component";
import {removeItem} from "../../utils";
import {FacetValueResult} from "@flux-capacitor-io/flux-host-typescriptmodels";
import {FacetUtils} from "../facet-utils";

@Component({
  selector: 'app-facet-filter',
  templateUrl: './facet-filter.component.html',
  styleUrls: ['./facet-filter.component.scss']
})
export class FacetFilterComponent implements AfterViewInit {
  @Output() selectedValuesChange: EventEmitter<string[]> = new EventEmitter<string[]>();

  private ngZone = inject(NgZone);
  private _selectedValues: string[];
  private _hasValue: boolean;
  private _facet: FacetStats;
  protected facetValues: FacetValueResultFormatted[] = [];
  protected term: any;
  protected filteredValues: FacetValueResultFormatted[];
  private maxItems: number = 100;
  private valuesMap: Dictionary<FacetValueResultFormatted>;
  private from: number = 0;
  private endReached: boolean;

  @ViewChild("dropdown") dropdown: ElementRef;

  updateFilteredValues = (values: FacetValueResultFormatted[]) => {
    const selectedValues = this.selectedValues || [];
    this.filteredValues = this.valuesMap ? selectedValues.map(v => this.valuesMap[v]).concat(values.slice(0, this.from + this.maxItems)
      .filter(v => !selectedValues.includes(v.value))) : [];
  };

  ngAfterViewInit(): void {
    $(this.dropdown.nativeElement).on("show.bs.dropdown", () => {
      this.ngZone.run(() => {
        this.facetValues = this.formatFacetValues(this._facet);
        this.facetValues = lodash.sortBy(this.facetValues, f => f.formattedValue || f.value);
        this.valuesMap = lodash.keyBy(this.facetValues, (v) => v.value);
        this.updateFilteredValues(this.facetValues);
      })
      return true;
    });
    $(this.dropdown.nativeElement).on("hide.bs.dropdown", () => {
      this.term = null;
      return true;
    });
  }

  @Input()
  set selectedValues(values: string[]) {
    values = (Array.isArray(values) ? values : [values]).filter(a => a);
    this._selectedValues = values;
    this._hasValue = values?.length > 0;
  }

  get selectedValues() {
    return this._selectedValues;
  }

  @Input()
  set facet(facet: FacetStats) {
    this._facet = facet;
    this.facetValues = [];
    this.setDefaultValueFormatter(facet);
  }

  private formatFacetValues(facet: FacetStats) {
    if (!facet.valueFormatter) {
      return facet.values;
    }
    return (facet.values || []).map(f => {
      const facetFormatted = f as FacetValueResultFormatted;
      facetFormatted.formattedValue = this.facet.valueFormatter(f.value);
      return facetFormatted;
    });
  }

  get facet() {
    return this._facet;
  }

  get hasValue() {
    return this._hasValue;
  }

  protected removeFilter = () => {
    this.selectedValues = null;
    this.selectedValuesChange.emit(this.facet.valuesSelectedCallback
      ? this.facet.valuesSelectedCallback(this.facet, this.selectedValues) : this.selectedValues);
  }

  trackByFacetValue = (index: number, record: FacetValueResultFormatted) => record?.value;

  facetValueSelected(facetValue: FacetValueResultFormatted, selected: boolean) {
    const currentSelection = this.selectedValues || [];
    if (selected) {
      currentSelection.push(facetValue.value);
    } else {
      removeItem(currentSelection, facetValue.value);
    }
    this.selectedValues = currentSelection?.length > 0 ? currentSelection : null;
    this.selectedValuesChange.emit(this.facet.valuesSelectedCallback
      ? this.facet.valuesSelectedCallback(this.facet, this.selectedValues) : this.selectedValues);
  }

  getFacetName = (): string => this.facet.nameFormatter ? this.facet.nameFormatter(this.facet.name) : this.facet.name;

  get selectedValueFormatted() {
    return this.selectedValues
      ? this.facet.valueFormatter
        ? this.selectedValues.map(v => this.facet.valueFormatter(v)).join(", ")
        : this.selectedValues.join(", ")
      : null;
  }

  private setDefaultValueFormatter = (facet: FacetStats) => {
    if (facet.valueFormatter) {
      return;
    }
    if (FacetUtils.isBooleanFacet(facet)) {
      facet.valueFormatter = (value: string) => "true" === value ? "Yes" : "false" === value ? "No" : null;
    }
  }

  loadNextPage = () => {
    if (!this.endReached) {
      this.from += this.maxItems;
      this.updateFilteredValues(this.facetValues);
    }
  }
}

export interface FacetStats {
  name: string;
  values: FacetValueResult[];
  nameFormatter?: (name: string) => string;
  valueFormatter?: (value: string) => string;
  valuesSelectedCallback?: (facet: FacetStats, selectedValues: string[]) => string[];
}
