import {Component, inject, OnInit} from '@angular/core';
import {Handler} from "../../../common/handler";
import {View} from "../../../common/view";
import {
  Application,
  Cluster,
  DestroyApplication,
  FacetFilter,
  GetIssues,
  TaskSize
} from "@flux-capacitor-io/flux-host-typescriptmodels";
import {
  ApplicationDetailsModalComponent,
  ApplicationInfoDetailsComponentData
} from "../application-details-modal/application-details-modal.component";
import {cloneDeep} from "lodash";
import {HandleQuery} from "../../../common/handle-query";
import {subscribeTo} from "../../../common/app-common-utils";
import {lodash} from 'src/app/common/utils';
import {RouteData} from "../../../app-routing.module";
import {ActivatedRoute} from "@angular/router";
import {Observable} from "rxjs";

@Component({
  selector: 'app-application-details',
  templateUrl: './application-details.component.html',
  styleUrls: ['./application-details.component.scss']
})
@Handler()
export class ApplicationDetailsComponent extends View implements OnInit {
  application: Application;
  cluster: Cluster;
  route: ActivatedRoute = inject(ActivatedRoute);
  routeData: RouteData;
  taskSizes: Observable<TaskSize[]>;

  constructor() {
    super();
    this.routeData = this.route.snapshot.data as RouteData;
  }

  ngOnInit(): void {
    this.subscribeTo("getCluster").subscribe(c => this.cluster = c);
    this.subscribeTo("getApplication").subscribe(a => this.application = a);
    this.taskSizes = this.sendQuery("getTaskSizes");
  }

  destroyApplication = () => this.sendCommand("host.flux.service.organisation.api.DestroyApplication", <DestroyApplication> {
    applicationId: this.application.applicationId
  });

  configureApplication = () => this.openModal(ApplicationDetailsModalComponent, <ApplicationInfoDetailsComponentData>{
    cluster: cloneDeep(this.cluster),
    application: cloneDeep(this.application)
  });

  getDefaultFacetFilters = (): FacetFilter[] => [{
    facetName: "details/applicationId",
    values: [this.application.applicationId]
  }];

  @HandleQuery("getIssues", { caching: true })
  getIssues(query: GetIssues) {
    query.facetFilters = lodash.cloneDeep(query.facetFilters || []);
    query.facetFilters.push(...this.getDefaultFacetFilters());
    return subscribeTo("host.flux.service.monitoring.issue.api.GetIssues", query, {caching: false});
  }
}

