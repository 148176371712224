<div class="card hover-show w-100">
  <div class="card-body">
    <div class="row d-flex gx-3 mb-3">
      <div class="col flex-grow-1 w-0">
        <div class="text-truncate">
          <span class="h3 notranslate">
            {{ historyItem.item.description }}
          </span>
        </div>
      </div>
      <div class="d-none d-md-block text-muted ms-auto col-6 col-md-3 col-xl-2 text-end notranslate">
        <span class="bi bi-clock pe-3"></span>
        <span>{{ historyItem.item.timestamp | timestamp }}</span>
      </div>
    </div>
    <div class="row gx-3">
      <div class="d-block d-md-none text-muted col-12 col-md-4 col-xl-2 notranslate">
        <span class="bi bi-clock pe-3"></span>
        <span>{{ historyItem.item.timestamp | timestamp }}</span>
      </div>
      <div class="text-muted col-12 col-md-4 col-lg-4 col-xl-2 notranslate" *ngIf="cluster | async as cluster">
        <span class="bi bi-window-stack pe-2"></span>
        <span>{{ cluster.details.name }}</span>
      </div>
      <div class="text-muted col-12 col-md-4 col-lg-4 col-xl-2 notranslate" *ngIf="application | async as application">
        <span class="bi bi-window pe-2"></span>
        <span>{{ application.details.name }}</span>
      </div>
      <div class="text-muted col-12 col-md-4 col-lg-4 col-xl-3 notranslate" *ngIf="historyItem.taskId">
        <span class="bi bi-layers pe-2"></span>
        <span>{{ historyItem.taskId }}</span>
      </div>
      <div class="text-muted ms-auto col-12 col-md-4 col-lg-4 col-xl-2 text-md-end notranslate" *ngIf="historyItem.item.userId">
        <span class="bi bi-person pe-2"></span>
        <span>{{ historyItem.item.userId }}</span>
      </div>
    </div>
  </div>
</div>
