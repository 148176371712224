<div class="position-relative w-100 h-100 mb-0">
  <ng-container *ngIf="getColor(taskWithContext.task.status) | async as color">
    <div class="card hover-show w-100 mb-0" [ngClass]="taskWithContext.task.status !== 'running' ? 'border-'+color : ''">
      <div class="card-body">
        <h2 class="card-title text-center notranslate">
          <i class="bi bi-window me-2"></i>
          <ng-container *ngIf="openedInClusterDetails() else applicationDetails">
            <a class="text-black hover-underline" [routerLink]="'../application/' + taskWithContext.application.applicationId">
              <span>{{taskWithContext.task.taskId}}</span>
            </a>
          </ng-container>
          <ng-template #applicationDetails>
            <span>{{taskWithContext.task.taskId}}</span>
          </ng-template>
        </h2>
        <h5 class="text-muted text-center notranslate">
          <ng-container *ngIf="openedInClusterDetails()">
            <span>{{taskWithContext.application.details.name | sentenceCase}}</span>
            <span> | </span>
          </ng-container>
          <span>{{taskWithContext.task.details.applicationVersion | sentenceCase}}</span>
        </h5>
        <h5 class="text-center notranslate" [ngClass]="'text-'+color">
          <app-task-status [statusInfo]="taskStatusInfo"></app-task-status>
          {{taskWithContext.task.status | sentenceCase}}
        </h5>
      </div>
    </div>
  </ng-container>
</div>
