<app-overview [query]="query" (dataChange)="data = $event" [windowScroll]="true">
  <div class="header-bar align-items-center row mb-4">
    <div class="col-auto ms-auto">
      <app-date-period-range [period]="period.getValue()" (periodChange)="period.next($event)"></app-date-period-range>
    </div>
  </div>
  <div class="overview-items">
    <div class="row mx-0">
      <ng-container *ngFor="let record of data; trackBy: trackByForRecord">
        <div class="col-12 col-md-4">
          <app-invoice-overview-item class="h-100 d-flex" [invoice]="record"></app-invoice-overview-item>
        </div>
      </ng-container>
    </div>
  </div>
  <div class="empty-container col-12 h-100 d-flex align-items-center justify-content-center flex-column">
    <span class="h1 mb-2">No invoices found</span>
  </div>
</app-overview>
