<app-form *ngIf="data" (submit)="updateInfo()">
  <div class="card">
    <div class="card-header">
      <h3 *ngIf="isNewAuthorisation" class="card-header-title">New authorisation</h3>
      <h3 *ngIf="!isNewAuthorisation" class="card-header-title notranslate">{{ authorisationHeader() }}</h3>
    </div>
    <div class="card-body">
      <div class="row">
        <div class="col-12 col-md-6">
          <app-form-field label="Organisation">
            <app-search [minCharacters]="0" [(ngModel)]="nominatorOrganisation" required
                        [searchFunction]="searchOrganisations" [inputFormatter]="organisationFormatter"
                        dataKey="info.name" (ngModelChange)="setNominator($event)" [disabled]="!nominatorEditable"
                        placeholder="Search for an organisation"></app-search>
          </app-form-field>
        </div>
        <div class="col-12 col-md-6">
          <app-form-field label="Role">
            <app-select required [options]="authorisationRoles"
                        [(ngModel)]="data.authorisation.role"></app-select>
          </app-form-field>
        </div>
        <div class="col-12">
          <!--                    <app-form-field class="col-6" label="Sources">-->
          <!--                        <app-sources-selector positionClass="position-fixed" [sourceProvider]="sourceProvider" (updated)="updateEntityIds($event)"></app-sources-selector>-->
          <!--                    </app-form-field>-->
        </div>
      </div>
    </div>
    <div class="card-footer">
      <div class="d-flex justify-content-between">
        <a class="btn btn-secondary" data-bs-dismiss="modal">Cancel</a>
        <button type="submit" class="btn btn-primary">Save changes</button>
      </div>
    </div>
  </div>
</app-form>
