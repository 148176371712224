<ng-container *ngIf="period">
  <app-date-range [(ngModel)]="period" (ngModelChange)="dateRangeChanged($event)" class="btn-group"
                  [inputMode]="'button'" [ranges]="ranges">
    <div class="w-auto" role="group">
      <i class="me-2 bi bi-calendar3"></i>
      <span class="me-2 notranslate">{{dateLabel}}</span>
      <i class="bi bi-chevron-down"></i>
    </div>
    <ng-container class="input-group-append">
      <div class="btn btn-outline-primary" (click)="movePeriodBack()">
        <i class="bi bi-arrow-left"></i>
      </div>
      <div class="btn btn-outline-primary" (click)="movePeriodForward()">
        <i class="bi bi-arrow-right"></i>
      </div>
    </ng-container>
  </app-date-range>
</ng-container>
