<app-overview [query]="query" (dataChange)="data = $event" [windowScroll]="true">
  <div class="header-bar align-items-center row mb-4">
    <div class="col">
      <app-filter [(filterTerm)]="term" (onUpdate)="executeQuery()"
                  localStorageKey="cluster-overview-term" placeholder="Search"></app-filter>
    </div>
    <div class="col-auto ms-auto d-flex flex-row align-items-center">
      <button (click)="createCluster()" type="button" class="btn btn-primary">
        <i class="bi bi-plus me-md-2"></i>
        <span class="d-none d-md-inline">Add cluster</span>
      </button>
    </div>
  </div>
  <div class="overview-items">
    <div class="row mx-0">
      <ng-container *ngFor="let record of data; trackBy: trackByForRecord">
        <div class="col-12 col-md-4">
            <app-cluster-overview-item class="h-100 d-flex" [cluster]="record" [team]="team"></app-cluster-overview-item>
        </div>
      </ng-container>
    </div>
  </div>
  <div class="empty-container col-12 h-100 d-flex align-items-center justify-content-center flex-column">
    <span class="h1 mb-2">No clusters found</span>
    <span class="text-secondary">Try adjusting your filters or search term or
      <span class="text-primary hover-underline pointer" (click)="createCluster()">
        <span class="me-2">create a new cluster</span>
      </span>
    </span>
  </div>
</app-overview>
