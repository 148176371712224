import {AfterViewInit, Component} from '@angular/core';
import {View} from "../../../common/view";
import {GetInvoices, Invoice, Organisation} from "@flux-capacitor-io/flux-host-typescriptmodels";
import {ComparatorChain} from "../../../common/comparator-chain";
import {mergeMap, Observable} from "rxjs";
import {HandleQuery} from "../../../common/handle-query";
import {Handler} from "../../../common/handler";
import {map} from "rxjs/operators";
import {sort} from "../../../common/utils";
import {LocalStorageValue} from '../../../utils/local-storage-value';
import {DateFieldRange} from '../../../common/date/date-range/date-field-range';
import {PERIOD_RANGES} from '../../../common/date/date-period-range/date-period-range.component';

@Component({
  selector: 'app-invoice-overview',
  templateUrl: './invoice-overview.component.html',
  styleUrls: ['./invoice-overview.component.scss']
})
@Handler()
export class InvoiceOverviewComponent extends View implements AfterViewInit {
  invoiceComparator: ComparatorChain = new ComparatorChain("-invoiceDate", "-invoiceNumber");
  organisation: Organisation;
  term: string;
  query: Observable<Invoice[]>;
  data: Invoice[] = [];
  period: LocalStorageValue<DateFieldRange> = new LocalStorageValue<DateFieldRange>("invoice-overview-range", {
    start: PERIOD_RANGES.lastTwelveMonths.start.toISOString(),
    end: PERIOD_RANGES.lastTwelveMonths.end.toISOString(),
    label: PERIOD_RANGES.lastTwelveMonths.label
  });

  ngAfterViewInit(): void {
    this.period.subscribe(next => this.executeQuery());
  }

  executeQuery() {
    this.query = this.getInvoices();
  }

  trackByForRecord(index: number, record: Invoice) {
    return record.id;
  }

  @HandleQuery("getInvoices")
  getInvoices(): Observable<Invoice[]> {
    return this.subscribeTo("getOrganisation")
      .pipe(mergeMap((o: Organisation) => this.sendQuery("host.flux.service.invoicing.api.GetInvoices", <GetInvoices> {
        organisationId: o.organisationId,
        timeRange: this.period.getValue()
      }))).pipe(map((i: Invoice[]) => sort(i, this.invoiceComparator)));
  }
}
