<div class="card hover-show w-100">
  <div class="card-body">
    <app-confirmation-toggle (confirmed)="deletePasskey()">
      <button type="button" class="btn position-absolute top-0 end-0 to-show text-danger"
              role="button" aria-expanded="false">
        <i class="bi bi-trash"></i>
      </button>
      <div class="modal-content">
        <div class="modal-header">
          <h3 class="modal-title">Delete passkey</h3>
          <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
        </div>
        <div class="modal-body">
          <p>
            Are you sure you want to delete the passkey <b>{{record.name}}</b>? This will cause this device not to be able
            to login using biometric data.
          </p>
        </div>
        <div class="modal-footer justify-content-between">
          <a type="button" class="btn btn-secondary" data-bs-dismiss="modal">Cancel</a>
          <button type="button" class="btn btn-danger" (click)="deletePasskey()" data-bs-dismiss="modal">
            Delete passkey
          </button>
        </div>
      </div>
    </app-confirmation-toggle>
    <h2 class="card-title text-center notranslate mb-0">
      <i class="bi bi-fingerprint me-2"></i>
      <span class="me-2">{{record.name}}</span>
      <app-passkey-status [status]="record.state"></app-passkey-status>
    </h2>
  </div>
</div>
