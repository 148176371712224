import {Component, OnInit} from '@angular/core';
import {Handler} from "../../../../common/handler";
import {View} from "../../../../common/view";
import {Observable} from "rxjs";
import {GetPasskeys, Passkey, RequestPasskey} from "@flux-capacitor-io/flux-host-typescriptmodels";
import {AppContext} from "../../../../app-context";
import {AppCommonUtils} from "../../../../common/app-common-utils";
import {HandleCommand} from "../../../../common/handle-command";

@Component({
  selector: 'app-passkey-overview',
  templateUrl: './passkey-overview.component.html',
  styleUrls: ['./passkey-overview.component.scss']
})
@Handler()
export class PasskeyOverviewComponent extends View implements OnInit {
  passKeys: Observable<Passkey[]>;
  data: Passkey[] = [];

  ngOnInit(): void {
    this.executeQuery();
  }

  executeQuery(): void {
    this.passKeys = this.subscribeTo("host.flux.service.authentication.zitadel.api.GetPasskeys", <GetPasskeys> {
      userId: AppContext.userProfile.userId
    }, {caching: false});
  }

  requestPasskey = () => {
    this.sendCommand("host.flux.service.authentication.zitadel.api.RequestPasskey", <RequestPasskey>{
      userId: AppContext.userProfile.userId
    }, () => AppCommonUtils.registerSuccess("Passkey request email has been sent to your email address"));
  }

  trackByForRecord = (index: number, record: Passkey) => record.id;

  @HandleCommand("reloadOverview")
  reloadOverview() {
    this.executeQuery();
  }
}
