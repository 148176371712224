<app-form (submit)="updateInfo()">
  <div class="card">
    <div class="card-header">
      <h3 *ngIf="isNew" class="card-header-title">{{data.systemUser ? 'New API key' : 'New user'}}</h3>
      <h3 *ngIf="!isNew" class="card-header-title notranslate">{{data.systemUser ? data.details.firstName : data.details.firstName + ' ' + data.details.lastName}}</h3>
    </div>
    <div class="card-body">
      <div class="row">
        <ng-container *ngIf="data.systemUser else normalUser">
          <div class="col-12 col-md-6">
            <app-form-field label="Name">
              <input required [(ngModel)]="data.details.firstName" type="text" class="form-control">
            </app-form-field>
          </div>
        </ng-container>
        <ng-template #normalUser>
          <div class="col-12 col-md-6">
            <app-form-field label="Email">
              <input required [disabled]="!isNew" [(ngModel)]="email" type="email" class="form-control">
            </app-form-field>
          </div>
        </ng-template>
        <ng-container *ngIf="isNew && !data.systemUser">
          <ng-container *ngIf="organisation">
            <div class="col-12 col-md-6">
              <app-form-field label="Role">
                <app-select required [options]="authorisationRoles" [(ngModel)]="role"></app-select>
              </app-form-field>
            </div>
          </ng-container>
        </ng-container>
      </div>
    </div>

    <div class="card-footer">
      <div class="d-flex justify-content-between">
        <a class="btn btn-secondary" data-bs-dismiss="modal">Cancel</a>
        <button type="submit" class="btn btn-primary">{{isNew ? data.systemUser ? 'Create API key' : 'Link user' : 'Save changes'}}</button>
      </div>
    </div>
  </div>
</app-form>
