import {Component, Input} from '@angular/core';
import {PasskeyState} from "@flux-capacitor-io/flux-host-typescriptmodels";

@Component({
  selector: 'app-passkey-status',
  templateUrl: './passkey-status.component.html',
  styleUrls: ['./passkey-status.component.scss']
})
export class PasskeyStatusComponent {
  @Input() status: PasskeyState;
}
