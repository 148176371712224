import {Component, inject, OnInit} from '@angular/core';
import {View} from "../../../common/view";
import {
  Cluster,
  ClusterSize,
  DestroyCluster,
  FacetFilter,
  GetIssues,
  Team
} from "@flux-capacitor-io/flux-host-typescriptmodels";
import {Handler} from "../../../common/handler";
import {ClusterStatusInfo} from "../cluster-overview/cluster-overview.component";
import {
  ClusterDetailsModalComponent,
  ClusterDetailsModalComponentData
} from "../cluster-details-modal/cluster-details-modal.component";
import {cloneDeep} from "lodash";
import {HandleQuery} from "../../../common/handle-query";
import {subscribeTo} from "../../../common/app-common-utils";
import {ActivatedRoute} from "@angular/router";
import {RouteData} from "../../../app-routing.module";
import {Observable} from "rxjs";

@Component({
  selector: 'app-cluster-details',
  templateUrl: './cluster-details.component.html',
  styleUrls: ['./cluster-details.component.scss']
})
@Handler()
export class ClusterDetailsComponent extends View implements OnInit {
  private route: ActivatedRoute = inject(ActivatedRoute);
  cluster: Cluster;
  clusterStatusInfo: ClusterStatusInfo;
  team: Team;
  routeData: RouteData;
  clusterSizes: Observable<ClusterSize[]>;

  constructor() {
    super();
    this.routeData = this.route.snapshot.data as RouteData;
  }

  ngOnInit(): void {
    this.sendCommandAndForget("openIssueDetails", this.route.snapshot.queryParamMap.get("issueId"));
    this.subscribeTo("getTeam").subscribe(t => this.team = t);
    this.subscribeTo("getCluster").subscribe(c => this.cluster = c);
    this.subscribeTo("getClusterStatusInfo").subscribe(s => this.clusterStatusInfo = s);
    this.clusterSizes = this.sendQuery("getClusterSizes");
  }

  configureCluster = () => this.openModal(ClusterDetailsModalComponent, <ClusterDetailsModalComponentData>{
    cluster: cloneDeep(this.cluster),
    team: this.team
  });

  destroyCluster = () => this.sendCommand("host.flux.service.organisation.api.DestroyCluster", <DestroyCluster> {
    clusterId: this.cluster.clusterId
  });

  getDefaultFacetFilters = (): FacetFilter[] => [];

  @HandleQuery("getIssues", { caching: true })
  getIssues(query: GetIssues) {
    query.facetFilters = query.facetFilters || [];
    query.facetFilters.push(...this.getDefaultFacetFilters());
    return subscribeTo("host.flux.service.monitoring.issue.api.GetIssues", query, {caching: false});
  }
}
