import {Component} from '@angular/core';
import {AppContext} from '../../../app-context';
import {Handler} from "../../../common/handler";
import {View} from "../../../common/view";
import {AppCommonUtils} from "../../../common/app-common-utils";
import {RequestPasswordChange} from "@flux-capacitor-io/flux-host-typescriptmodels";

@Component({
  selector: 'app-edit-profile-security',
  templateUrl: './edit-profile-security.component.html',
  styleUrls: ['./edit-profile-security.component.scss']
})
@Handler()
export class EditProfileSecurityComponent extends View {
  changePassword = () => {
    this.sendCommand("host.flux.service.authentication.zitadel.api.RequestPasswordChange", <RequestPasswordChange>{
      userId: AppContext.userProfile.userId
    }, () => AppCommonUtils.registerSuccess("Password request email has been sent to your email address"));
  }
}
