import {Component, Input} from '@angular/core';
import {Cluster, Organisation} from "@flux-capacitor-io/flux-host-typescriptmodels";
import {HandleQuery} from "../../../../../common/handle-query";
import {Observable} from "rxjs";
import {map} from "rxjs/operators";
import {TaskWithContext} from "../../../../clusters/cluster-routing/cluster-routing.component";
import {Handler} from "../../../../../common/handler";
import {View} from "../../../../../common/view";

@Component({
  selector: 'app-organisation-overview-item-cluster',
  templateUrl: './organisation-overview-item-cluster.component.html',
  styleUrls: ['./organisation-overview-item-cluster.component.scss']
})
@Handler()
export class OrganisationOverviewItemClusterComponent extends View {
  @Input() organisation: Organisation;
  @Input() cluster: Cluster;

  getClusterLink = () => `organisation/${this.organisation.organisationId}/cluster/${this.cluster.clusterId}`;

  @HandleQuery("getCluster")
  getCluster(): Observable<Cluster> {
    return this.subscribeTo("getOrganisation").pipe(map((o: Organisation) => o.teams.flatMap(t => t.clusters)
      .find(c => c.clusterId === this.cluster.clusterId)));
  }

  @HandleQuery("getTasks")
  getTasks(): Observable<TaskWithContext[]> {
    return this.subscribeTo("getCluster").pipe(map((c: Cluster) => c.applications.flatMap(
      a => a.tasks.map(t => (<TaskWithContext>{
        task: t,
        application: a
      })))));
  }
}
