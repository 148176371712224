import {Component, Input} from '@angular/core';
import {View} from "../../../../common/view";
import {DownloadInvoice, Invoice} from "@flux-capacitor-io/flux-host-typescriptmodels";
import {Handler} from "../../../../common/handler";
import {downloadAny} from '../../../../common/download.utils';

@Component({
  selector: 'app-invoice-overview-item',
  templateUrl: './invoice-overview-item.component.html',
  styleUrls: ['./invoice-overview-item.component.scss']
})
@Handler()
export class InvoiceOverviewItemComponent extends View {
  @Input() invoice: Invoice;

  downloadInvoice() {
    this.sendQuery("host.flux.service.invoicing.api.DownloadInvoice",
      <DownloadInvoice> {id: this.invoice.id}, {caching: false, responseType: 'blob'})
      .subscribe(blob => downloadAny(
        blob, `flux-host-invoice-${this.invoice.invoiceNumber}.pdf`, 'application/pdf'));
  }
}
