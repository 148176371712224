<div class="position-relative w-100 h-100 mb-0">
  <ng-container *ngFor="let task of application.tasks; index as i; let first = first; trackBy: trackByForTask">
    <ng-container *ngIf="!first && i < 3">
      <ng-container *ngIf="getColor(task.status) | async as color">
        <div class="card hover-show w-100 h-100 position-absolute top-0 start-0"
             [style.margin-top.px]="i*10" [style.margin-left.px]="i*10" [style.z-index]="i*-1"
             [ngClass]="task.status !== 'running' ? 'border-'+color : ''">
          <div class="card-body"></div>
        </div>
      </ng-container>
    </ng-container>
  </ng-container>
  <div class="card hover-show w-100 mb-0">
    <div class="card-body">
      <h2 class="card-title text-center notranslate">
        <i class="bi bi-window me-2"></i>
        <a class="text-black hover-underline" [routerLink]="'../application/' + application.applicationId">
          <span>{{application.details.name}}</span>
        </a>
      </h2>
      <h5 class="text-muted text-center notranslate">
        {{(application.applicationVersion || "latest") | sentenceCase}} | {{application.details.descriptor.size | sentenceCase}}
      </h5>
      <hr class="card-divider"/>
      <ng-container *ngIf="application.tasks.length else noTasks">
        <div class="list-group-flush list-group overflow-y-auto overflow-x-hidden">
          <div class="list-group-item py-1">
            <div class="row">
              <div class="col"><small>Tasks</small></div>
              <div class="col-4 notranslate">
                <app-task-summary></app-task-summary>
              </div>
            </div>
          </div>
        </div>
      </ng-container>
      <ng-template #noTasks>
        <div class="d-flex align-items-center justify-content-center">
          <span class="text-danger">No tasks running for this application!</span>
        </div>
      </ng-template>
    </div>
  </div>
</div>
