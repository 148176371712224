<ng-container *ngIf="organisation">
  <div class="d-flex flex-column">
    <div class="position-absolute top-0 end-0">
      <button type="button" class="btn to-show" role="button" aria-expanded="false" (click)="configureOrganisation()">
        <i class="bi bi-pencil"></i>
      </button>
    </div>
    <div class="row gx-3">
      <div class="col-auto">
        <span class="h1 m-0"><i class="bi bi-window-stack"></i></span>
      </div>
      <div class="col">
        <span class="text-black hover-underline pointer" (click)="configureOrganisation()">
          <h1 class="header-title text-truncate notranslate">{{organisation.details.name}}</h1>
        </span>
      </div>
    </div>
    <div class="row">
      <div class="col-6 col-md-4">
        <div class="col-form-label"><label class="fw-regular">Tasks</label></div>
        <app-task-summary></app-task-summary>
      </div>
    </div>
    <app-tab-panel class="d-flex flex-column flex-grow-1" [activeIndex]="routeData?.activeTabIndex">
      <app-tab-item [templateRef]="clustersRef" routerLink="../clusters">
        <span class="tab-item">Clusters</span>
        <ng-template #clustersRef>
          <div class="tab-content">
            <app-cluster-overview [team]="team"></app-cluster-overview>
          </div>
        </ng-template>
      </app-tab-item>
      <app-tab-item [templateRef]="usersRef" routerLink="../users">
        <span class="tab-item">Users</span>
        <ng-template #usersRef>
          <div class="tab-content">
            <app-user-overview></app-user-overview>
          </div>
        </ng-template>
      </app-tab-item>
      <app-tab-item [templateRef]="deploymentKeysRef" routerLink="../api-keys">
        <span class="tab-item">API keys</span>
        <ng-template #deploymentKeysRef>
          <div class="tab-content">
            <app-api-key-overview></app-api-key-overview>
          </div>
        </ng-template>
      </app-tab-item>
      <app-tab-item [templateRef]="environmentVariablesRef" routerLink="../variables">
        <span class="tab-item">Variables</span>
        <ng-template #environmentVariablesRef>
          <div class="tab-content">
            <app-environment-variable-overview></app-environment-variable-overview>
          </div>
        </ng-template>
      </app-tab-item>
      <app-tab-item [templateRef]="usageRef" routerLink="../usage">
        <span class="tab-item">Usage</span>
        <ng-template #usageRef>
          <div class="tab-content">
            <app-usage-overview></app-usage-overview>
          </div>
        </ng-template>
      </app-tab-item>
      <app-tab-item [templateRef]="historyRef" routerLink="../history">
        <span class="tab-item">History</span>
        <ng-template #historyRef>
          <div class="tab-content">
            <app-history-overview></app-history-overview>
          </div>
        </ng-template>
      </app-tab-item>
      <app-tab-item [templateRef]="invoiceRef" routerLink="../invoices">
        <span class="tab-item">Invoices</span>
        <ng-template #invoiceRef>
          <div class="tab-content">
            <app-invoice-overview></app-invoice-overview>
          </div>
        </ng-template>
      </app-tab-item>
    </app-tab-panel>
  </div>
</ng-container>
