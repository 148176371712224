<app-form (submit)="updateInfo()">
  <div class="card">
    <div class="card-header">
      <h3 class="card-header-title">{{isNew ? 'New environment variable' : 'Update environment variable'}}</h3>
    </div>
    <div class="card-body">
      <div class="row">
        <div class="col-12 col-md-6">
          <app-form-field label="Name">
            <input required [(ngModel)]="data.details.name" type="text" class="form-control">
          </app-form-field>
        </div>
        <div class="col-12 col-md-6">
          <app-form-field label="Value">
            <input required autocomplete="off"
                   [(ngModel)]="data.details.value" [type]="data.details.secret ? 'password' : 'text'" class="form-control">
          </app-form-field>
        </div>
        <div class="col-auto">
          <div class="form-check form-switch">
            <input class="form-check-input" type="checkbox" role="switch" id="taskHistory"
                   [(ngModel)]="data.details.secret">
            <label class="form-check-label" for="taskHistory">Secret</label>
          </div>
        </div>
      </div>
    </div>

    <div class="card-footer">
      <div class="d-flex justify-content-between">
        <a class="btn btn-secondary" data-bs-dismiss="modal">Cancel</a>
        <button type="submit" class="btn btn-primary">{{isNew ? 'Create variable' : 'Save changes'}}</button>
      </div>
    </div>
  </div>
</app-form>
