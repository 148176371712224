import {AfterViewInit, Component, OnInit} from '@angular/core';
import {Task} from "@flux-capacitor-io/flux-host-typescriptmodels";
import {Observable} from "rxjs";
import {map} from "rxjs/operators";
import {filterByTerm, sort} from "../../../common/utils";
import {ComparatorChain} from "../../../common/comparator-chain";
import {Handler} from "../../../common/handler";
import {View} from "../../../common/view";
import {HandleQuery} from "../../../common/handle-query";
import {TaskStatusInfo} from "../task-status/task-status.component";
import taskStatusInfos from "../task-status-info.json";
import {TaskWithContext} from "../../clusters/cluster-routing/cluster-routing.component";

@Component({
  selector: 'app-task-overview',
  templateUrl: './task-overview.component.html',
  styleUrls: ['./task-overview.component.scss']
})
@Handler()
export class TaskOverviewComponent extends View implements OnInit, AfterViewInit {
  private comparatorChain = new ComparatorChain("cluster.details.name",
    "application.details.name", "task.details.applicationVersion", "task.status", "task.taskId");

  term: string;
  query: Observable<TaskWithContext[]>;
  data: TaskWithContext[] = [];

  ngOnInit() {
  }

  ngAfterViewInit(): void {
    this.executeQuery();
  }

  executeQuery = () => {
    this.query = this.subscribeTo("getTasks")
      .pipe(map((t: TaskWithContext[]) => t.filter(filterByTerm(this.term))))
      .pipe(map(c => sort(c, this.comparatorChain)));
  };

  trackByForRecord(index: number, record: TaskWithContext) {
    return record.task.taskId;
  }

  @HandleQuery("getTaskStatusInfo", {caching: true})
  getTaskStatusInfo(task: Task): Observable<TaskStatusInfo> {
    return this.subscribeTo("getTask", task.taskId).pipe(map(
      (t: TaskWithContext) => taskStatusInfos[t.task.status] as TaskStatusInfo))
  }
}
