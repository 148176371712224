<div class="row">
  <div class="d-none d-md-block col-md-2">
    <ng-container *ngTemplateOutlet="filtersRef"></ng-container>
  </div>
  <div class="col mh-100 d-flex flex-column align-items-stretch">
    <app-overview class="" [query]="query" (dataChange)="data = $event" [windowScroll]="true">
      <div class="header-bar align-items-center row mb-4">
        <div class="col-auto d-flex d-md-none px-1">
          <button class="btn text-black p-0 btn-close-wrapper border-0 rounded-circle" (click)="openFiltersInModal()"
                  [style.width.px]="40" [style.height.px]="40" [ngClass]="facetUtils.hasActiveFilters(facetFilters) ? 'active': ''">
            <span class="bi bi-filter m-0 h1"></span>
          </button>
        </div>
        <div class="col">
          <app-filter [(filterTerm)]="term" (onUpdate)="executeQuery()"
                      localStorageKey="issues-overview-term" placeholder="Search"></app-filter>
        </div>
        <div class="col-auto dropdown">
          <button class="btn btn-secondary dropdown-toggle" type="button" data-bs-toggle="dropdown"
                  aria-expanded="false">
            Channels
          </button>
          <ul class="dropdown-menu">
            <li class="notranslate" *ngFor="let channel of slackChannels; trackBy: trackByChannel">
              <a class="dropdown-item d-flex align-items-center  justify-content-between  pointer" (click)="openSlackModal(channel)">
                <div class="d-flex align-items-center">
                  <i class="bi bi-slack"></i>
                  <span class="ms-2 me-2">{{ channel.details.name }}</span>
                </div>
                <i class="bi bi-pencil ms-auto"></i>
              </a>
            </li>
            <li><a class="dropdown-item pointer" (click)="addNewSlackChannel()">Add new channel</a>
            </li>
          </ul>
        </div>
      </div>
      <div class="overview-items">
        <div class="row mx-0">
          <ng-container *ngFor="let record of data; trackBy: trackByForRecord">
            <app-issue-overview-item [issue]="record"></app-issue-overview-item>
          </ng-container>
        </div>
      </div>
      <div class="empty-container col-12 h-100 d-flex align-items-center justify-content-center flex-column">
        <span class="h1 mb-2">No issues found</span>
        <span class="text-secondary">Try adjusting your filters or search term</span>
      </div>
    </app-overview>
  </div>
</div>

<ng-template #filtersInModal>
  <div class="modal-content">
    <div class="card">
      <div class="card-header">
        <h3 class="card-header-title">
          <i class="bi bi-filter me-2"></i>
          <span>Filters</span>
        </h3>
      </div>
      <div class="card-body">
        <ng-container *ngTemplateOutlet="filtersRef"></ng-container>
      </div>
      <div class="card-footer">
        <div class="d-flex justify-content-between">
          <a class="btn btn-secondary" data-bs-dismiss="modal">Close</a>
        </div>
      </div>
    </div>
  </div>
</ng-template>

<ng-template #filtersRef>
  <div class="position-relative w-100">
    <app-facets-overview [query]="facets" (facetFiltersChange)="facetFilters = $event"
                         (onUpdate)="executeQuery()" localStorageKey="cluster-overview-facets"></app-facets-overview>
  </div>
</ng-template>
