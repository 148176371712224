import {Component} from '@angular/core';
import {copyToClipboard} from '../../../common/utils';
import {View} from '../../../common/view';
import {Handler} from '../../../common/handler';
import {EncryptSecret, Secret} from '@flux-capacitor-io/flux-host-typescriptmodels';
import {AppCommonUtils} from '../../../common/app-common-utils';

@Component({
    selector: 'app-encrypt',
    templateUrl: './encrypt.component.html',
    styleUrls: ['./encrypt.component.css']
})
@Handler()
export class EncryptComponent extends View {
    value: string;
    result: string;

    encrypt() {
        this.sendCommand("host.flux.service.common.api.EncryptSecret", <EncryptSecret>{secret: this.value},
          (result: Secret) => {
                copyToClipboard(result.secret).subscribe({
                    error: () => console.error("Failed to copy value to clipboard: " + result),
                    complete: () => AppCommonUtils.registerSuccess("Encrypted value was copied to your clipboard!")
                });
            });
    }
}
