import {Component, Input, OnInit} from '@angular/core';
import {Application, Cluster, Team} from "@flux-capacitor-io/flux-host-typescriptmodels";
import {cloneDeep} from "lodash";
import {
  ApplicationDetailsModalComponent,
  ApplicationInfoDetailsComponentData
} from "../../../applications/application-details-modal/application-details-modal.component";
import {ClusterStatusInfo} from "../cluster-overview.component";
import {Handler} from "../../../../common/handler";
import {View} from "../../../../common/view";
import {HandleQuery} from "../../../../common/handle-query";
import {Observable} from "rxjs";
import {TaskWithContext} from "../../cluster-routing/cluster-routing.component";
import {map} from "rxjs/operators";

@Component({
  selector: 'app-cluster-overview-item',
  templateUrl: './cluster-overview-item.component.html',
  styleUrls: ['./cluster-overview-item.component.scss']
})
@Handler()
export class ClusterOverviewItemComponent extends View implements OnInit {
  @Input() team: Team;
  @Input() cluster: Cluster;
  clusterStatusInfo: ClusterStatusInfo;

  ngOnInit(): void {
    this.subscribeTo("getClusterStatusInfo", this.cluster).subscribe(s => this.clusterStatusInfo = s);
  }

  trackByApplicationId = (index: number, record: Application) => record.applicationId;

  configureApplication = (application?: Application) => this.openModal(ApplicationDetailsModalComponent, <ApplicationInfoDetailsComponentData>{
    cluster: cloneDeep(this.cluster),
    application: application ? cloneDeep(application) : null
  });

  @HandleQuery("getCluster")
  getCluster(): Observable<Cluster> {
    return this.subscribeTo("getTeam").pipe(map((t: Team) => t.clusters
      .find(c => c.clusterId === this.cluster.clusterId)));
  }
}
