<div class="card hover-show w-100">
  <div class="card-body">
    <div class="row d-flex gx-3">
      <div class="col flex-grow-1 w-0">
        <span class="notranslate">
          <i class="me-2 bi bi-bar-chart-line"></i>
          {{ usageEntry.resourceType | sentenceCase }}
        </span>
      </div>
      <div class="d-none d-md-block text-center col notranslate">
        <span>{{ usageEntry.descriptor.size | sentenceCase }}</span>
      </div>
      <div class="d-none d-md-block ms-auto col text-end notranslate">
        <span>{{ usageEntry.hours }} {{usageEntry.hours > 1 ? 'hours' : 'hour'}}</span>
      </div>
    </div>
  </div>
</div>
