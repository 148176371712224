import {Injectable} from "@angular/core";
import {HandleQuery} from "../../common/handle-query";
import {TaskStatus} from "@flux-capacitor-io/flux-host-typescriptmodels";
import {StandaloneHandler} from "../../common/standalone-handler";

@Injectable({
  providedIn: "root"
})
export class TaskService extends StandaloneHandler {

  @HandleQuery("getTaskStatusColor")
  getTaskStatusColor(taskStatus: TaskStatus): string {
    switch (taskStatus) {
      case TaskStatus.launching: return "info";
      case TaskStatus.running: return "success";
      case TaskStatus.destroying: return "warning";
      case TaskStatus.destroyed: return "danger";
    }
  }
}
