import {Component, OnInit} from '@angular/core';
import {Application, Cluster, ConfigureApplication, TaskSize} from "@flux-capacitor-io/flux-host-typescriptmodels";
import {uuid} from "../../../common/utils";
import {Handler} from "../../../common/handler";
import {View} from "../../../common/view";
import {Observable} from "rxjs";

@Component({
  selector: 'app-application-details-modal',
  templateUrl: './application-details-modal.component.html',
  styleUrls: ['./application-details-modal.component.scss']
})
@Handler()
export class ApplicationDetailsModalComponent extends View implements OnInit {
  data: ApplicationInfoDetailsComponentData;
  isNewRecord: boolean;
  taskSizes: Observable<TaskSize[]>;

  ngOnInit() {
    this.isNewRecord = !this.data.application?.applicationId;
    this.data.application = this.data.application || this.defaultRecord();
    this.taskSizes = this.sendQuery("getTaskSizes");
  }

  updateInfo = () => this.sendCommand("host.flux.service.organisation.api.ConfigureApplication", <ConfigureApplication>{
    clusterId: this.data.cluster.clusterId,
    applicationId: this.data.application.applicationId,
    details: this.data.application.details
  });

  defaultRecord = (): Application => ({
    applicationId: uuid(),
    details: {
      descriptor: {

      }
    }
  });
}

export interface ApplicationInfoDetailsComponentData {
  cluster: Cluster;
  application: Application;
}
