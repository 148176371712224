import {AfterViewInit, Component} from '@angular/core';
import {View} from "../../../common/view";
import {InstantRange, UsageEntry} from "@flux-capacitor-io/flux-host-typescriptmodels";
import {ComparatorChain} from "../../../common/comparator-chain";
import {Observable} from "rxjs";
import {map} from "rxjs/operators";
import {LocalStorageValue} from "../../../utils/local-storage-value";
import {DateFieldRange} from "../../../common/date/date-range/date-field-range";
import {PERIOD_RANGES} from "../../../common/date/date-period-range/date-period-range.component";
import {sort} from '../../../common/utils';

@Component({
  selector: 'app-usage-overview',
  templateUrl: './usage-overview.component.html',
  styleUrls: ['./usage-overview.component.scss']
})
export class UsageOverviewComponent extends View implements AfterViewInit {
  comparator: ComparatorChain = new ComparatorChain("resourceType", "descriptor.size", "hours");
  query: Observable<UsageEntry[]>;
  data: UsageEntry[] = [];
  period: LocalStorageValue<DateFieldRange> = new LocalStorageValue<DateFieldRange>("usage-overview-period", {
    start: PERIOD_RANGES.thisMonth.start.toISOString(),
    end: PERIOD_RANGES.thisMonth.end.toISOString(),
    label: PERIOD_RANGES.thisMonth.label
  });

  ngAfterViewInit(): void {
    this.period.subscribe(() => this.executeQuery());
  }

  executeQuery() {
    const period = this.period.getValue();
    this.query = this.subscribeTo("getUsage", <InstantRange> {
      start: period.start,
      end: period.end
    }).pipe(map((u: UsageEntry[]) => sort(u, this.comparator)));
  }

  trackByForRecord(index: number, record: UsageEntry) {
    return `${record.resourceType}-${record.descriptor.size}`;
  }
}
