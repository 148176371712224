<div *ngIf="entries">
  <div class="card">
    <div class="card-header row h-auto">
      <div class="col-auto d-flex flex-md-row align-items-center">
        <div class="d-none d-md-flex me-4">
          <ng-container *ngTemplateOutlet="buttons"></ng-container>
        </div>
        <div class="notranslate me-1">
          <i class="bi bi-hash"></i>
          <span class="align-middle ms-2 me-3">{{ entries[index]?.sequence || '-' }}</span>
        </div>
        <div class="notranslate me-1 ">
          <i class="bi bi-clock-history"></i>
          <span class="align-middle ms-2">{{ entries[index]?.timestamp | timestamp }}</span>
        </div>
      </div>

      <div class="col-auto ms-auto dropdown">
        <button class="btn btn-primary dropdown-toggle" type="button" data-bs-toggle="dropdown" aria-expanded="false">
          Actions
        </button>
        <app-issue-actions-dropdown [issue]="issue"></app-issue-actions-dropdown>
      </div>
    </div>

    <div class="card-body">
      <app-issue-entry [entry]="entries[index]"></app-issue-entry>
    </div>

    <div class="card-footer">
      <div class="d-flex justify-content-between">
        <a class="btn btn-secondary" data-bs-dismiss="modal">Cancel</a>
        <div class="d-flex d-md-none">
          <ng-container *ngTemplateOutlet="buttons"></ng-container>
        </div>
      </div>
    </div>
  </div>
</div>

<ng-template #buttons>
  <div class="btn-group" role="group">
    <button class="btn btn-outline-primary" (click)="goToBeginning()" [disabled]="!hasPrevEntry()">
      <i class="bi bi-skip-start-fill"></i>
    </button>
    <button class="btn btn-outline-primary" (click)="prevEntry()" [disabled]="!hasPrevEntry()">
      <i class="bi bi-caret-left-fill"></i>
    </button>
    <button class="btn btn-outline-primary" (click)="nextEntry()" [disabled]="!hasNextEntry()">
      <i class="bi bi-caret-right-fill"></i>
    </button>
    <button class="btn btn-outline-primary" (click)="goToEnd()" [disabled]="!hasNextEntry()">
      <i class="bi bi-skip-end-fill"></i>
    </button>
  </div>
</ng-template>
