<div class="card hover-show w-100">
  <div class="card-body">
    <app-confirmation-toggle (confirmed)="delete()">
      <button type="button" class="btn position-absolute top-0 start-0 to-show"
              role="button" aria-expanded="false">
        <i class="bi bi-trash"></i>
      </button>
      <div class="modal-content">
        <div class="modal-header">
          <h3 class="modal-title">Delete Environment Variable</h3>
          <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>

        </div>
        <div class="modal-body">
          <p>
            Are you sure you want to delete this variable?
          </p>
        </div>
        <div class="modal-footer justify-content-between">
          <a type="button" class="btn btn-secondary" data-bs-dismiss="modal">Cancel</a>
          <button type="button" class="btn btn-primary" (click)="delete()" data-bs-dismiss="modal">
            Delete variable
          </button>
        </div>
      </div>
    </app-confirmation-toggle>
    <div class="position-absolute top-0 end-0">
      <button [ngClass]="{'d-none': !variable.details.secret}" (click)="showSecret()"
              type="button" class="btn to-show-inline" role="button" data-bs-title="Show secret"
              aria-expanded="false">
        <i class="bi bi-eye"></i>
      </button>
      <button [ngClass]="{'d-none': variable.details.secret || variable.details.value === original.details.value}"
              (click)="hideSecret()"
              type="button" class="btn to-show-inline" role="button" data-bs-title="Hide secret"
              aria-expanded="false">
        <i class="bi bi-eye-slash"></i>
      </button>
      <button type="button" class="btn to-show-inline" role="button" aria-expanded="false" (click)="open()">
        <i class="bi bi-pencil"></i>
      </button>
    </div>
    <h2 class="card-title text-center notranslate">
      <i class="me-2 bi bi-link-45deg"></i>
      <span class="me-2">{{variable.details.name}}</span>
    </h2>
    <h5 class="text-muted text-center notranslate">
      <span class="vertical-align-middle">{{variable.details.secret ? '**********' : variable.details.value}}</span>
    </h5>
  </div>
</div>
