import {Component, inject} from '@angular/core';
import {HandleQuery} from "../../../common/handle-query";
import {Observable, of} from "rxjs";
import {
  Application,
  Cluster,
  GetUsage,
  InstantRange,
  SlackAlertChannel,
  Task,
  TaskUsageEntry,
  Team
} from "@flux-capacitor-io/flux-host-typescriptmodels";
import {AppCommonUtils, InjectorProvider} from "../../../common/app-common-utils";
import {map, tap} from "rxjs/operators";
import {View} from "../../../common/view";
import {Handler} from "../../../common/handler";
import {ActivatedRoute, Router} from "@angular/router";
import clusterStatusInfos from "../cluster-status-info.json";
import {ClusterStatusInfo} from "../cluster-overview/cluster-overview.component";
import {HandleCommand} from "../../../common/handle-command";
import {
  IssueNavigatorComponent,
  IssueNavigatorComponentData
} from "../../issues/issue-navigator/issue-navigator.component";
import {Location} from "@angular/common";
import {GetApplications} from "../../../app.component";
import {AnimationTypes, ModalOptions} from '../../../common/modal/modal';

@Component({
  selector: 'app-cluster-routing',
  templateUrl: './cluster-routing.component.html',
  styleUrls: ['./cluster-routing.component.scss']
})
@Handler()
export class ClusterRoutingComponent extends View {
  private route = inject(ActivatedRoute);
  private router = inject(Router);
  private location = inject(Location);
  private clusterId: string;

  constructor() {
    super();
    this.route.params.subscribe(params => this.clusterId = params["clusterId"]);
  }

  @HandleQuery("getTeam")
  getTeam(): Observable<Team> {
    return this.subscribeTo("getTeams").pipe(map((teams: Team[]) =>
      teams.find(t => t.clusters.some(c => c.clusterId === this.clusterId))));
  }

  @HandleQuery("getCluster")
  getCluster(): Observable<Cluster> {
    return this.subscribeTo("getClusters")
      .pipe(map((clusters: Cluster[]) => clusters.find(t => t.clusterId === this.clusterId)))
      .pipe(tap(c => {
        if (!c) {
          AppCommonUtils.registerInfo("Cluster has been destroyed");
          this.router.navigate(["../../"], {relativeTo: this.route});
        }
      }));
  }

  @HandleQuery("getApplication")
  getApplication(applicationId?: string): Observable<Application> {
    return applicationId ? this.subscribeTo("getCluster").pipe(map((c: Cluster) => c.applications
        .find(a => a.applicationId === applicationId)))
      : of(null);
  }

  @HandleQuery("getEntityId")
  getEntityId(): Observable<string> {
    return this.subscribeTo("getCluster").pipe(map((c: Cluster) => c.clusterId));
  }

  @HandleQuery("getEntity")
  getEntity(): Observable<any> {
    return this.subscribeTo("getCluster");
  }

  @HandleQuery("getClusterStatusInfo")
  getClusterStatusInfo(): Observable<ClusterStatusInfo> {
    return this.subscribeTo("getCluster").pipe(map(c => clusterStatusInfos[c.status] as ClusterStatusInfo));
  }

  @HandleQuery("getTasks")
  getTasks(): Observable<TaskWithContext[]> {
    return this.subscribeTo("getCluster").pipe(map((c: Cluster) => c.applications.flatMap(
      a => a.tasks.map(t =>  (<TaskWithContext>{
        cluster: c,
        application: a,
        task: t
      })))));
  }

  @HandleQuery("getTask")
  getTask(taskId: string): Observable<TaskWithContext> {
    return this.subscribeTo("getTasks").pipe(map((tasks: TaskWithContext[]) => tasks.find(t => t.task.taskId === taskId)));
  }

  @HandleQuery("getApplicationByTaskId")
  getApplicationByTaskId(taskId: string): Observable<Application> {
    return this.subscribeTo("getApplications", <GetApplications> {clusterId: this.clusterId})
      .pipe(map((applications: Application[]) => applications.find(a => a.tasks.some(t => t.taskId === taskId))));
  }

  @HandleQuery("findAlertChannels")
  findAlertChannels(): Observable<SlackAlertChannel[]> {
    return this.subscribeTo("getCluster").pipe(map((c: Cluster) => c.alertChannels));
  }

  @HandleCommand("openIssueDetails")
  openIssueDetails(issueId: string) {
    if (issueId) {
      const modalConfig: ModalOptions = {
        cssClass: "modal-dialog-centered modal-xxl",
        animation: AnimationTypes.zoomIn,
        closeCallback: () => this.location.go(InjectorProvider.injector.get(Router)
          .createUrlTree([], {relativeTo: this.route}).toString())
      };
      this.openModal(IssueNavigatorComponent, <IssueNavigatorComponentData>{
        issueId: issueId
      }, modalConfig);
    }
    return true;
  }

  @HandleQuery("getUsage")
  getUsage(timeRange: InstantRange): Observable<TaskUsageEntry[]> {
    return this.sendQuery("host.flux.service.usage.api.GetUsage", <GetUsage>{
      timeRange: timeRange,
      facetFilters: [{
        facetName: "clusterId",
        values: [this.clusterId]
      }]
    });
  }
}

export interface TaskWithContext {
  task: Task;
  application: Application;
  cluster: Cluster;
}
