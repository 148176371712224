import {FacetStats} from "./facet-filter/facet-filter.component";
import {FacetFilter} from "@flux-capacitor-io/flux-host-typescriptmodels";

export class FacetUtils {

  static isBooleanFacet(facet: FacetStats): boolean {
    return facet.values.every(v => ["false", "true"].includes(v.value))
  }

  static hasActiveFilters = (filters: FacetFilter[]): boolean => !!filters?.length;
}
