<div class="row">
  <div class="col-auto">
    <i class="bi bi-window-stack me-2"></i>
  </div>
  <div class="col text-truncate notranslate d-inline-flex align-items-start">
    <a class="text-black hover-underline" [routerLink]="getClusterLink()"><small>{{cluster.details.name}}</small></a>
  </div>
  <div class="col-4 notranslate">
    <app-task-summary></app-task-summary>
  </div>
</div>
