import {AfterViewInit, Component} from '@angular/core';
import {View} from "../../../common/view";
import {ComparatorChain} from "../../../common/comparator-chain";
import {Observable} from "rxjs";
import {DeploymentKeyResult, GetApiKeys, UserProfile} from "@flux-capacitor-io/flux-host-typescriptmodels";
import {UserDetailsComponent} from "../../users/user-details/user-details.component";
import {map} from "rxjs/operators";
import {Handler} from "../../../common/handler";
import {sort} from '../../../common/utils';
import {downloadAny} from "../../../common/download.utils";

@Component({
  selector: 'app-api-key-overview',
  templateUrl: './api-key-overview.component.html',
  styleUrls: ['./api-key-overview.component.scss']
})
@Handler()
export class ApiKeyOverviewComponent extends View implements AfterViewInit {
  private userComparator = new ComparatorChain("details.lastName", "details.firstName", "userId");
  term: string;
  query: Observable<UserProfile[]>;
  data: UserProfile[] = [];

  ngAfterViewInit(): void {
    this.executeQuery();
  }

  create = () => this.openModal(UserDetailsComponent, <UserProfile> {
    details: {},
    receivedAuthorisations: [],
    systemUser: true
  }, undefined, (result : DeploymentKeyResult) => downloadAny(
    JSON.stringify(result), `${result.keyId}.json`, "application/json"));

  executeQuery() {
    this.query = this.subscribeTo("getApiKeys", <GetApiKeys>{term: this.term})
      .pipe(map((u: UserProfile[]) => sort(u, this.userComparator)));
  }

  trackByForRecord(index: number, record: UserProfile) {
    return record.userId;
  }
}
