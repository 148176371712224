<div class="card hover-show w-100">
  <div class="card-body">
    <app-confirmation-toggle *ngIf="appContext.isAdmin()" (confirmed)="deleteApiKey()">
      <button type="button" class="btn position-absolute top-0 start-0 to-show"
              role="button" aria-expanded="false">
        <i class="bi bi-trash"></i>
      </button>
      <div class="modal-content">
        <div class="modal-header">
          <h3 class="modal-title">Delete API key</h3>
          <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>

        </div>
        <div class="modal-body">
          <p>
            Are you sure you want to delete this API key.
            Future requests cannot be made for this key and this can cause existing pipelines to become invalid?
          </p>
        </div>
        <div class="modal-footer justify-content-between">
          <a type="button" class="btn btn-secondary" data-bs-dismiss="modal">Cancel</a>
          <button type="button" class="btn btn-primary" (click)="deleteApiKey()" data-bs-dismiss="modal">
            Delete API key
          </button>
        </div>
      </div>
    </app-confirmation-toggle>
    <div *ngIf="appContext.isAdmin()" class="position-absolute top-0 end-0">
      <app-confirmation-toggle (confirmed)="cycleDeploymentKey()">
        <button type="button" class="btn to-show-inline" role="button" data-bs-title="Cycle API key"
                aria-expanded="false">
          <i class="bi bi-arrow-clockwise"></i>
        </button>
        <div class="modal-content">
          <div class="modal-header">
            <h3 class="modal-title">Cycle API key</h3>
            <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
          </div>
          <div class="modal-body">
            <p>This will cause the old keys to not work anymore. Are you sure you want to cycle the deployment keys?</p>
          </div>
          <div class="modal-footer justify-content-between">
            <a type="button" class="btn btn-secondary" data-bs-dismiss="modal">Cancel</a>
            <button type="button" class="btn btn-primary" (click)="cycleDeploymentKey()" data-bs-dismiss="modal">
              Cycle API key
            </button>
          </div>
        </div>
      </app-confirmation-toggle>
      <button type="button" class="btn to-show-inline" role="button" aria-expanded="false" (click)="openKeyDetails()">
        <i class="bi bi-pencil"></i>
      </button>
    </div>
    <h2 class="card-title text-center notranslate">
      <i class="me-2 bi bi-link-45deg"></i>
      <span class="me-2">{{user.details.firstName}} {{user.details.lastName}}</span>
    </h2>
    <h5 class="text-muted text-center notranslate">
      <span class="vertical-align-middle">{{user.details.email}}</span>
    </h5>
  </div>
</div>
