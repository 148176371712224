<ng-container *ngIf="application">
  <div class="d-flex flex-column">
    <div class="position-absolute top-0 end-0">
      <app-confirmation-toggle (confirmed)="destroyApplication()">
        <button type="button" class="btn" role="button" data-bs-title="Destroy application" aria-expanded="false">
          <i class="bi bi-trash3"></i>
        </button>
        <div class="modal-content">
          <div class="modal-header">
            <h3 class="modal-title">Destroy application</h3>
            <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
          </div>
          <div class="modal-body">
            <p>
              This will destroy the application and removes it from the cluster.
              Are you sure you want to destroy this application?
            </p>
          </div>
          <div class="modal-footer justify-content-between">
            <a type="button" class="btn btn-secondary" data-bs-dismiss="modal">Cancel</a>
            <button type="button" class="btn btn-danger" (click)="destroyApplication()" data-bs-dismiss="modal">
              Destroy application
            </button>
          </div>
        </div>
      </app-confirmation-toggle>
      <button type="button" class="btn" role="button" aria-expanded="false" (click)="configureApplication()">
        <i class="bi bi-pencil"></i>
      </button>
    </div>
    <div class="row gx-3">
      <div class="col-auto">
        <span class="h1 m-0"><i class="bi bi-window"></i></span>
      </div>
      <div class="col">
        <span class="text-black hover-underline pointer" (click)="configureApplication()">
          <h1 class="header-title text-truncate notranslate">{{ application.details.name }}</h1>
        </span>
      </div>
    </div>
    <div class="row">
      <div class="col-6 col-md-4">
        <app-form-field label="Version" [editMode]="false" [value]="application.applicationVersion" emptyReadOnlyValue="Latest">
          <input [(ngModel)]="application.applicationVersion" type="text" class="form-control" required>
        </app-form-field>
      </div>
      <div class="col-6 col-md-4">
        <div class="col-form-label"><label class="fw-regular">Tasks</label></div>
        <app-task-summary></app-task-summary>
      </div>
      <div class="col-6 col-md-4">
        <app-form-field label="Size" [editMode]="false" [value]="application.details.descriptor.size | sentenceCase">
          <app-select [(ngModel)]="application.details.descriptor.size" type="text" required
                      [optionsProvider]="taskSizes"></app-select>
        </app-form-field>
      </div>
    </div>
    <app-tab-panel class="d-flex flex-column flex-grow-1" [activeIndex]="routeData?.activeTabIndex">
      <app-tab-item [templateRef]="tasksRef" routerLink="../tasks">
        <span class="tab-item">Tasks</span>
        <ng-template #tasksRef>
          <div class="tab-content">
            <app-task-overview></app-task-overview>
          </div>
        </ng-template>
      </app-tab-item>
      <app-tab-item [templateRef]="environmentVariablesRef" routerLink="../variables">
        <span class="tab-item">Variables</span>
        <ng-template #environmentVariablesRef>
          <div class="tab-content">
            <app-environment-variable-overview></app-environment-variable-overview>
          </div>
        </ng-template>
      </app-tab-item>
      <app-tab-item [templateRef]="usageRef" routerLink="../usage">
        <span class="tab-item">Usage</span>
        <ng-template #usageRef>
          <div class="tab-content">
            <app-usage-overview></app-usage-overview>
          </div>
        </ng-template>
      </app-tab-item>
      <app-tab-item [templateRef]="issuesRef" routerLink="../issues" *ngIf="false">
        <span class="tab-item">Issues</span>
        <ng-template #issuesRef>
          <div class="tab-content">
            <app-issues-overview></app-issues-overview>
          </div>
        </ng-template>
      </app-tab-item>
      <app-tab-item [templateRef]="historyRef" routerLink="../history">
        <span class="tab-item">History</span>
        <ng-template #historyRef>
          <div class="tab-content">
            <app-history-overview></app-history-overview>
          </div>
        </ng-template>
      </app-tab-item>
      <app-tab-item tabClass="ms-auto" externalLink="https://google.com">
        <span class="tab-item">Logs</span>
      </app-tab-item>
    </app-tab-panel>
  </div>
</ng-container>
